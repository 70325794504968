.list {
  list-style-position: outside;
  list-style-type: disc;
  padding-left: calc(var(--theme_spacing) / 2);
  margin-top: calc(var(--theme_spacing) / 2);
  margin-bottom: 0;
}

.list li {
  margin-left: 15px;
}

.banner {
  display: block;
  text-decoration: none;
}

.list a {
  cursor: pointer;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: var(--theme_font_family);
  vertical-align: bottom;
  text-decoration: underline;
  color: var(--theme_primary_color);
}

.list a:hover,
.list a:focus,
.list a:active {
  color: var(--theme_primary_color_hover);
}
