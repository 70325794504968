html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
