.alert {
  padding: var(--theme_spacing);
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
}

.wrapper {
  display: table;
}

.content + .content {
  padding-left: var(--theme_spacing);
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.variant-primary {
  color: var(--theme_alert_primary_text_color);
  background-color: var(--theme_alert_primary_background_color);
  border-color: var(--theme_alert_primary_border_color);
}

.variant-success {
  color: var(--theme_alert_success_text_color);
  background-color: var(--theme_alert_success_background_color);
  border-color: var(--theme_alert_success_border_color);
}

.variant-secondary {
  color: var(--theme_alert_secondary_text_color);
  background-color: var(--theme_alert_secondary_background_color);
  border-color: var(--theme_alert_secondary_border_color);
}

.variant-error {
  color: var(--theme_alert_error_text_color);
  background-color: var(--theme_alert_error_background_color);
  border-color: var(--theme_alert_error_border_color);
}

.variant-warning {
  color: var(--theme_alert_warning_text_color);
  background-color: var(--theme_alert_warning_background_color);
  border-color: var(--theme_alert_warning_border_color);
}
