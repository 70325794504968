.main {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
}

.text {
  margin-top: 8px;
  font-size: 10pt;
  font-family: var(--theme_font_family);
  color: var(--theme_loading_text_color);
}
