.checkbox {
  display: inline-block;
  border-radius: 3px;
  position: relative;
  text-align: center;
  width: var(--width);
  height: var(--height);
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
}

.disabled:not(.readOnly) {
  opacity: var(--theme_disabled_opacity);
  cursor: not-allowed;
}

.readOnly {
  cursor: unset;
}

.xs {
  --height: 18px;
  --width: 18px;
  --borderWidth: 3px;
}

.sm {
  --height: 22px;
  --width: 22px;
  --borderWidth: 3px;
}

.md {
  --height: 28px;
  --width: 28px;
  --borderWidth: 4px;
}

.lg {
  --height: 32px;
  --width: 32px;
  --borderWidth: 5px;
}

.xl {
  --height: 36px;
  --width: 36px;
  --borderWidth: 6px;
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  appearance: none;
  z-index: 1;
  cursor: inherit;
}

.tick {
  display: block;
  margin: 0 auto;
  transform: rotate(45deg);
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  border-bottom: var(--borderWidth) solid transparent;
  border-right: var(--borderWidth) solid transparent;
}

/* primary contained */

.variant-contained.color-primary {
  border-color: var(--theme_primary_color);
  background-color: var(--theme_primary_color);
}

.variant-contained.color-primary .input:checked + .tick {
  border-bottom-color: var(--theme_primary_contrast_color);
  border-right-color: var(--theme_primary_contrast_color);
}

.variant-contained.color-primary:not(.disabled, .readOnly):hover,
.variant-contained.color-primary:not(.disabled, .readOnly):focus {
  border-color: var(--theme_primary_color_hover);
  background-color: var(--theme_primary_color_hover);
}

/* primary outlined */

.variant-outlined.color-primary {
  border-color: var(--theme_primary_color);
}

.variant-outlined.color-primary .input:checked + .tick {
  border-bottom-color: var(--theme_primary_color);
  border-right-color: var(--theme_primary_color);
}

.variant-outlined.color-primary:not(.disabled, .readOnly):hover,
.variant-outlined.color-primary:not(.disabled, .readOnly):focus {
  border-color: var(--theme_primary_color_hover);
}

/* secondary contained */

.variant-contained.color-secondary {
  border-color: var(--theme_secondary_color);
  background-color: var(--theme_secondary_color);
}

.variant-contained.color-secondary .input:checked + .tick {
  border-bottom-color: var(--theme_secondary_contrast_color);
  border-right-color: var(--theme_secondary_contrast_color);
}

.variant-contained.color-secondary:not(.disabled, .readOnly):hover,
.variant-contained.color-secondary:not(.disabled, .readOnly):focus {
  border-color: var(--theme_secondary_color_hover);
  background-color: var(--theme_secondary_color_hover);
}

/* secondary outlined */

.variant-outlined.color-secondary {
  border-color: var(--theme_secondary_color);
}

.variant-outlined.color-secondary .input:checked + .tick {
  border-bottom-color: var(--theme_secondary_color);
  border-right-color: var(--theme_secondary_color);
}
