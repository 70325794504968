.wrapper {
  max-width: 450px;
  margin: 0 auto;
}

.banner {
  text-decoration: none;
  display: block;
}

@media (max-width: 450px) {
  .banner {
    margin-left: calc(-1 * var(--theme_spacing));
    margin-right: calc(-1 * var(--theme_spacing));
  }
}
