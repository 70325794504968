.wrapper {
  text-align: center;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.listItem {
  display: inline-block;
}

.listItem + .listItem {
  margin-left: 12px;
}

.starImage {
  width: 32px;
  height: 32px;
}
