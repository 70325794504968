.block {
  font-size: 12px;
  text-align: center;
  white-space: pre-line;
  color: var(--theme_dimmed_text_color);
  font-family: var(--theme_font_family);
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.block a {
  color: inherit;
}

.hr {
  height: 1px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--theme_dimmed_text_color);
}

.links {
  display: block;
}

.link {
  display: inline-block;
}

:global([dir='ltr']) .link + .link {
  margin-left: calc(var(--theme_spacing) / 2);
}

:global([dir='rtl']) .link + .link {
  margin-right: calc(var(--theme_spacing) / 2);
}

.row + .row {
  margin-top: calc(var(--theme_spacing) / 2);
}
