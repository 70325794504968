.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.content {
  border: 2px solid #f3f3f3;
  border-bottom: none;
  background: #fff;
  box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.8);
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--theme_spacing);
}

.logo {
  width: 64px;
  height: 64px;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  color: #dedfde;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 0;
  width: 42px;
  height: 42px;
  border: 0;
}

.text {
  margin-left: 16px;
}

.a2hs {
  margin-bottom: 98px;
}

@media (min-width: 960px) {
  .wrapper {
    border-left: 2px solid #f3f3f3;
    border-right: 2px solid #f3f3f3;
  }
}
