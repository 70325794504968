html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--theme_font_family);
  font-weight: 400;
  line-height: 1.4;
  font-size: 14px;
}
