.wrapper {
  background-color: #dff0f0;
  display: table;
  width: 100%;
  text-decoration: none;
}

.left,
.right {
  display: table-cell;
  vertical-align: middle;
  padding: var(--theme_spacing);
}

.left {
  text-align: center;
}

.right {
  text-align: left;
}

.heading {
  display: block;
  color: #312f3e;
  font-size: 17pt;
  font-weight: bold;
}

.text {
  display: block;
  color: black;
  font-size: 12pt;
}

.link {
  cursor: pointer;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: var(--theme_font_family);
  vertical-align: bottom;
  text-decoration: underline;
  color: var(--theme_primary_color);
}

.link:hover,
.link:focus,
.link:active {
  color: var(--theme_primary_color_hover);
}
