.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
  box-sizing: border-box;
}

.gutters {
  padding-left: var(--theme_spacing);
  padding-right: var(--theme_spacing);
}
