.wrapper {
  display: block;
  box-sizing: border-box;
  position: relative;
}

.input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #999;
  border-radius: 2px;
  line-height: 21px;
  font-size: 14px;
  font-family: inherit;
  outline: var(--theme_primary_color);
  resize: none;
  background-color: #fff;
}

.label {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  top: 12px;
  left: 6px;
  background-color: #fff;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  user-select: none;
}

.hint {
  display: block;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 21px;
  min-height: 21px;
  font-size: 14px;
}

.input:focus {
  border-color: var(--theme_primary_color);
}

.readOnly > .input {
  border-style: dotted;
}

.disabled > .input {
  cursor: not-allowed;
  border-style: dotted;
  border-color: #999;
}

.error > .label {
  color: #cd1719;
}

.error > .input:not(:focus) {
  border-color: #cd1719;
}

.error > .hint {
  color: #cd1719;
}
