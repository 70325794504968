.wrapper {
  width: 100%;
  display: flex;
}

.item + .item {
  padding-left: var(--theme_spacing);
}

.checkbox {
  top: 4px;
  position: relative;
}

.disabled .label {
  opacity: var(--theme_disabled_opacity);
}
