.spinner {
  animation: spinner_rotate 2s linear infinite;
  display: block;
}

.spinner .path {
  stroke-linecap: round;
  animation: spinner_dash 1.5s ease-in-out infinite;
}

@keyframes spinner_rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner_dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.variant-primary {
  stroke: var(--theme_primary_color);
}

.variant-primary.inverted {
  stroke: var(--theme_primary_contrast_color);
}

.variant-secondary {
  stroke: var(--theme_secondary_color);
}

.variant-secondary.inverted {
  stroke: var(--theme_secondary_contrast_color);
}

.size-xs {
  width: 18px;
  height: 18px;
}

.size-sm {
  width: 24px;
  height: 24px;
}

.size-md {
  width: 28px;
  height: 28px;
}

.size-lg {
  width: 32px;
  height: 32px;
}

.size-xl {
  width: 36px;
  height: 36px;
}
