.wrapper {
  display: inline-flex;
  align-items: center;
}

.statusAccepted {
  font-weight: bold;
  color: var(--accept_photo_shoot_offer_accepted_text_color);
}

.statusCancelled {
  color: var(--theme_dimmed_text_color);
}
