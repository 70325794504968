.wrapper {
  height: 56px;
  font-family: Helvetica, 'Droid Sans', 'Segoe UI', sans-serif;
  background-color: #205152;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  z-index: 1000;
}

.appBar {
  display: flex;
}

.logo {
  width: 150px;
  height: 28.79px;
  transform: scale(0.8);
}

.title {
  font-size: 18px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
}

.backButton {
  border: 0;
  background: none;
  color: inherit;
  padding: 0;
  display: flex;
  align-items: center;
  padding-right: var(--theme_spacing);
}
