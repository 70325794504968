.typography {
  line-height: 1.4;
  font-family: var(--theme_font_family);
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  display: inline-block;
}

.color-default {
  color: inherit;
}

.color-primary {
  color: var(--theme_primary_color);
}

.color-secondary {
  color: var(--theme_secondary_color);
}

.color-success {
  color: var(--theme_success_color);
}

.color-error {
  color: var(--theme_error_color);
}

.color-warning {
  color: var(--theme_warning_color);
}

.dimmed {
  opacity: var(--theme_disabled_opacity);
}

.truncated {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weight-normal {
  font-weight: normal;
}

.weight-bold {
  font-weight: bold;
}

.decoration-underline {
  text-decoration: underline;
}

.size-xs {
  font-size: 12px;
}

.size-sm {
  font-size: 14px;
}

.size-md {
  font-size: 16px;
}

.size-lg {
  font-size: 20px;
}

.size-xl {
  font-size: 24px;
}

.whiteSpace-normal {
  white-space: normal;
}

.whiteSpace-pre-line {
  white-space: pre-line;
}

.whiteSpace-inherit {
  white-space: inherit;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.fullWidth {
  width: 100%;
}
