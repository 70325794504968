.root {
  cursor: pointer;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: var(--theme_font_family);
  vertical-align: bottom;
  text-decoration: underline;
}

.content + .content {
  padding-left: 4px;
}

.preventDefault {
  cursor: inherit;
}

.disabled {
  opacity: var(--theme_disabled_opacity);
  cursor: not-allowed;
}

/* PRIMARY */

.color-primary {
  color: var(--theme_primary_color);
}

.color-primary:not(.disabled, .preventDefault):hover,
.color-primary:not(.disabled, .preventDefault):focus,
.color-primary:not(.disabled, .preventDefault):active {
  color: var(--theme_primary_color_hover);
}

/* SECONDARY */
.color-secondary {
  color: var(--theme_secondary_color);
}

.color-secondary:not(.disabled, .preventDefault):hover,
.color-secondary:not(.disabled, .preventDefault):focus,
.color-secondary:not(.disabled, .preventDefault):active {
  color: var(--theme_secondary_color_hover);
}
