.icon-accepted.variation-primary {
  background-image: url(1x/icon-accepted-primary-sm.png);
}

.icon-accepted.size-sm {
  width: 18px;
  height: 18px;
}

.icon-accepted.variation-primary {
  background-image: url(1x/icon-accepted-primary-md.png);
}

.icon-accepted.size-md {
  width: 21px;
  height: 21px;
}

.icon-accepted.variation-primary {
  background-image: url(1x/icon-accepted-primary-lg.png);
}

.icon-accepted.size-lg {
  width: 29px;
  height: 29px;
}

.icon-accepted.variation-secondary {
  background-image: url(1x/icon-accepted-secondary-sm.png);
}

.icon-accepted.variation-secondary {
  background-image: url(1x/icon-accepted-secondary-md.png);
}

.icon-accepted.variation-secondary {
  background-image: url(1x/icon-accepted-secondary-lg.png);
}

.icon-accepted.variation-black {
  background-image: url(1x/icon-accepted-black-sm.png);
}

.icon-accepted.variation-black {
  background-image: url(1x/icon-accepted-black-md.png);
}

.icon-accepted.variation-black {
  background-image: url(1x/icon-accepted-black-lg.png);
}

.icon-accepted.variation-white {
  background-image: url(1x/icon-accepted-white-sm.png);
}

.icon-accepted.variation-white {
  background-image: url(1x/icon-accepted-white-md.png);
}

.icon-accepted.variation-white {
  background-image: url(1x/icon-accepted-white-lg.png);
}

.icon-accepted.variation-success {
  background-image: url(1x/icon-accepted-success-sm.png);
}

.icon-accepted.variation-success {
  background-image: url(1x/icon-accepted-success-md.png);
}

.icon-accepted.variation-success {
  background-image: url(1x/icon-accepted-success-lg.png);
}

.icon-accepted.variation-error {
  background-image: url(1x/icon-accepted-error-sm.png);
}

.icon-accepted.variation-error {
  background-image: url(1x/icon-accepted-error-md.png);
}

.icon-accepted.variation-error {
  background-image: url(1x/icon-accepted-error-lg.png);
}

.icon-accepted.variation-warning {
  background-image: url(1x/icon-accepted-warning-sm.png);
}

.icon-accepted.variation-warning {
  background-image: url(1x/icon-accepted-warning-md.png);
}

.icon-accepted.variation-warning {
  background-image: url(1x/icon-accepted-warning-lg.png);
}

.icon-add_comment.variation-primary {
  background-image: url(1x/icon-add_comment-primary-sm.png);
}

.icon-add_comment.size-sm {
  width: 18px;
  height: 18px;
}

.icon-add_comment.variation-primary {
  background-image: url(1x/icon-add_comment-primary-md.png);
}

.icon-add_comment.size-md {
  width: 21px;
  height: 21px;
}

.icon-add_comment.variation-primary {
  background-image: url(1x/icon-add_comment-primary-lg.png);
}

.icon-add_comment.size-lg {
  width: 29px;
  height: 29px;
}

.icon-add_comment.variation-secondary {
  background-image: url(1x/icon-add_comment-secondary-sm.png);
}

.icon-add_comment.variation-secondary {
  background-image: url(1x/icon-add_comment-secondary-md.png);
}

.icon-add_comment.variation-secondary {
  background-image: url(1x/icon-add_comment-secondary-lg.png);
}

.icon-add_comment.variation-black {
  background-image: url(1x/icon-add_comment-black-sm.png);
}

.icon-add_comment.variation-black {
  background-image: url(1x/icon-add_comment-black-md.png);
}

.icon-add_comment.variation-black {
  background-image: url(1x/icon-add_comment-black-lg.png);
}

.icon-add_comment.variation-white {
  background-image: url(1x/icon-add_comment-white-sm.png);
}

.icon-add_comment.variation-white {
  background-image: url(1x/icon-add_comment-white-md.png);
}

.icon-add_comment.variation-white {
  background-image: url(1x/icon-add_comment-white-lg.png);
}

.icon-add_comment.variation-success {
  background-image: url(1x/icon-add_comment-success-sm.png);
}

.icon-add_comment.variation-success {
  background-image: url(1x/icon-add_comment-success-md.png);
}

.icon-add_comment.variation-success {
  background-image: url(1x/icon-add_comment-success-lg.png);
}

.icon-add_comment.variation-error {
  background-image: url(1x/icon-add_comment-error-sm.png);
}

.icon-add_comment.variation-error {
  background-image: url(1x/icon-add_comment-error-md.png);
}

.icon-add_comment.variation-error {
  background-image: url(1x/icon-add_comment-error-lg.png);
}

.icon-add_comment.variation-warning {
  background-image: url(1x/icon-add_comment-warning-sm.png);
}

.icon-add_comment.variation-warning {
  background-image: url(1x/icon-add_comment-warning-md.png);
}

.icon-add_comment.variation-warning {
  background-image: url(1x/icon-add_comment-warning-lg.png);
}

.icon-add_shoot.variation-primary {
  background-image: url(1x/icon-add_shoot-primary-sm.png);
}

.icon-add_shoot.size-sm {
  width: 18px;
  height: 18px;
}

.icon-add_shoot.variation-primary {
  background-image: url(1x/icon-add_shoot-primary-md.png);
}

.icon-add_shoot.size-md {
  width: 21px;
  height: 21px;
}

.icon-add_shoot.variation-primary {
  background-image: url(1x/icon-add_shoot-primary-lg.png);
}

.icon-add_shoot.size-lg {
  width: 29px;
  height: 29px;
}

.icon-add_shoot.variation-secondary {
  background-image: url(1x/icon-add_shoot-secondary-sm.png);
}

.icon-add_shoot.variation-secondary {
  background-image: url(1x/icon-add_shoot-secondary-md.png);
}

.icon-add_shoot.variation-secondary {
  background-image: url(1x/icon-add_shoot-secondary-lg.png);
}

.icon-add_shoot.variation-black {
  background-image: url(1x/icon-add_shoot-black-sm.png);
}

.icon-add_shoot.variation-black {
  background-image: url(1x/icon-add_shoot-black-md.png);
}

.icon-add_shoot.variation-black {
  background-image: url(1x/icon-add_shoot-black-lg.png);
}

.icon-add_shoot.variation-white {
  background-image: url(1x/icon-add_shoot-white-sm.png);
}

.icon-add_shoot.variation-white {
  background-image: url(1x/icon-add_shoot-white-md.png);
}

.icon-add_shoot.variation-white {
  background-image: url(1x/icon-add_shoot-white-lg.png);
}

.icon-add_shoot.variation-success {
  background-image: url(1x/icon-add_shoot-success-sm.png);
}

.icon-add_shoot.variation-success {
  background-image: url(1x/icon-add_shoot-success-md.png);
}

.icon-add_shoot.variation-success {
  background-image: url(1x/icon-add_shoot-success-lg.png);
}

.icon-add_shoot.variation-error {
  background-image: url(1x/icon-add_shoot-error-sm.png);
}

.icon-add_shoot.variation-error {
  background-image: url(1x/icon-add_shoot-error-md.png);
}

.icon-add_shoot.variation-error {
  background-image: url(1x/icon-add_shoot-error-lg.png);
}

.icon-add_shoot.variation-warning {
  background-image: url(1x/icon-add_shoot-warning-sm.png);
}

.icon-add_shoot.variation-warning {
  background-image: url(1x/icon-add_shoot-warning-md.png);
}

.icon-add_shoot.variation-warning {
  background-image: url(1x/icon-add_shoot-warning-lg.png);
}

.icon-attention.variation-primary {
  background-image: url(1x/icon-attention-primary-sm.png);
}

.icon-attention.size-sm {
  width: 18px;
  height: 18px;
}

.icon-attention.variation-primary {
  background-image: url(1x/icon-attention-primary-md.png);
}

.icon-attention.size-md {
  width: 21px;
  height: 21px;
}

.icon-attention.variation-primary {
  background-image: url(1x/icon-attention-primary-lg.png);
}

.icon-attention.size-lg {
  width: 29px;
  height: 29px;
}

.icon-attention.variation-secondary {
  background-image: url(1x/icon-attention-secondary-sm.png);
}

.icon-attention.variation-secondary {
  background-image: url(1x/icon-attention-secondary-md.png);
}

.icon-attention.variation-secondary {
  background-image: url(1x/icon-attention-secondary-lg.png);
}

.icon-attention.variation-black {
  background-image: url(1x/icon-attention-black-sm.png);
}

.icon-attention.variation-black {
  background-image: url(1x/icon-attention-black-md.png);
}

.icon-attention.variation-black {
  background-image: url(1x/icon-attention-black-lg.png);
}

.icon-attention.variation-white {
  background-image: url(1x/icon-attention-white-sm.png);
}

.icon-attention.variation-white {
  background-image: url(1x/icon-attention-white-md.png);
}

.icon-attention.variation-white {
  background-image: url(1x/icon-attention-white-lg.png);
}

.icon-attention.variation-success {
  background-image: url(1x/icon-attention-success-sm.png);
}

.icon-attention.variation-success {
  background-image: url(1x/icon-attention-success-md.png);
}

.icon-attention.variation-success {
  background-image: url(1x/icon-attention-success-lg.png);
}

.icon-attention.variation-error {
  background-image: url(1x/icon-attention-error-sm.png);
}

.icon-attention.variation-error {
  background-image: url(1x/icon-attention-error-md.png);
}

.icon-attention.variation-error {
  background-image: url(1x/icon-attention-error-lg.png);
}

.icon-attention.variation-warning {
  background-image: url(1x/icon-attention-warning-sm.png);
}

.icon-attention.variation-warning {
  background-image: url(1x/icon-attention-warning-md.png);
}

.icon-attention.variation-warning {
  background-image: url(1x/icon-attention-warning-lg.png);
}

.icon-back.variation-primary {
  background-image: url(1x/icon-back-primary-sm.png);
}

.icon-back.size-sm {
  width: 18px;
  height: 18px;
}

.icon-back.variation-primary {
  background-image: url(1x/icon-back-primary-md.png);
}

.icon-back.size-md {
  width: 21px;
  height: 21px;
}

.icon-back.variation-primary {
  background-image: url(1x/icon-back-primary-lg.png);
}

.icon-back.size-lg {
  width: 29px;
  height: 29px;
}

.icon-back.variation-secondary {
  background-image: url(1x/icon-back-secondary-sm.png);
}

.icon-back.variation-secondary {
  background-image: url(1x/icon-back-secondary-md.png);
}

.icon-back.variation-secondary {
  background-image: url(1x/icon-back-secondary-lg.png);
}

.icon-back.variation-black {
  background-image: url(1x/icon-back-black-sm.png);
}

.icon-back.variation-black {
  background-image: url(1x/icon-back-black-md.png);
}

.icon-back.variation-black {
  background-image: url(1x/icon-back-black-lg.png);
}

.icon-back.variation-white {
  background-image: url(1x/icon-back-white-sm.png);
}

.icon-back.variation-white {
  background-image: url(1x/icon-back-white-md.png);
}

.icon-back.variation-white {
  background-image: url(1x/icon-back-white-lg.png);
}

.icon-back.variation-success {
  background-image: url(1x/icon-back-success-sm.png);
}

.icon-back.variation-success {
  background-image: url(1x/icon-back-success-md.png);
}

.icon-back.variation-success {
  background-image: url(1x/icon-back-success-lg.png);
}

.icon-back.variation-error {
  background-image: url(1x/icon-back-error-sm.png);
}

.icon-back.variation-error {
  background-image: url(1x/icon-back-error-md.png);
}

.icon-back.variation-error {
  background-image: url(1x/icon-back-error-lg.png);
}

.icon-back.variation-warning {
  background-image: url(1x/icon-back-warning-sm.png);
}

.icon-back.variation-warning {
  background-image: url(1x/icon-back-warning-md.png);
}

.icon-back.variation-warning {
  background-image: url(1x/icon-back-warning-lg.png);
}

.icon-brief.variation-primary {
  background-image: url(1x/icon-brief-primary-sm.png);
}

.icon-brief.size-sm {
  width: 18px;
  height: 18px;
}

.icon-brief.variation-primary {
  background-image: url(1x/icon-brief-primary-md.png);
}

.icon-brief.size-md {
  width: 21px;
  height: 21px;
}

.icon-brief.variation-primary {
  background-image: url(1x/icon-brief-primary-lg.png);
}

.icon-brief.size-lg {
  width: 29px;
  height: 29px;
}

.icon-brief.variation-secondary {
  background-image: url(1x/icon-brief-secondary-sm.png);
}

.icon-brief.variation-secondary {
  background-image: url(1x/icon-brief-secondary-md.png);
}

.icon-brief.variation-secondary {
  background-image: url(1x/icon-brief-secondary-lg.png);
}

.icon-brief.variation-black {
  background-image: url(1x/icon-brief-black-sm.png);
}

.icon-brief.variation-black {
  background-image: url(1x/icon-brief-black-md.png);
}

.icon-brief.variation-black {
  background-image: url(1x/icon-brief-black-lg.png);
}

.icon-brief.variation-white {
  background-image: url(1x/icon-brief-white-sm.png);
}

.icon-brief.variation-white {
  background-image: url(1x/icon-brief-white-md.png);
}

.icon-brief.variation-white {
  background-image: url(1x/icon-brief-white-lg.png);
}

.icon-brief.variation-success {
  background-image: url(1x/icon-brief-success-sm.png);
}

.icon-brief.variation-success {
  background-image: url(1x/icon-brief-success-md.png);
}

.icon-brief.variation-success {
  background-image: url(1x/icon-brief-success-lg.png);
}

.icon-brief.variation-error {
  background-image: url(1x/icon-brief-error-sm.png);
}

.icon-brief.variation-error {
  background-image: url(1x/icon-brief-error-md.png);
}

.icon-brief.variation-error {
  background-image: url(1x/icon-brief-error-lg.png);
}

.icon-brief.variation-warning {
  background-image: url(1x/icon-brief-warning-sm.png);
}

.icon-brief.variation-warning {
  background-image: url(1x/icon-brief-warning-md.png);
}

.icon-brief.variation-warning {
  background-image: url(1x/icon-brief-warning-lg.png);
}

.icon-calendar.variation-primary {
  background-image: url(1x/icon-calendar-primary-sm.png);
}

.icon-calendar.size-sm {
  width: 18px;
  height: 18px;
}

.icon-calendar.variation-primary {
  background-image: url(1x/icon-calendar-primary-md.png);
}

.icon-calendar.size-md {
  width: 21px;
  height: 21px;
}

.icon-calendar.variation-primary {
  background-image: url(1x/icon-calendar-primary-lg.png);
}

.icon-calendar.size-lg {
  width: 29px;
  height: 29px;
}

.icon-calendar.variation-secondary {
  background-image: url(1x/icon-calendar-secondary-sm.png);
}

.icon-calendar.variation-secondary {
  background-image: url(1x/icon-calendar-secondary-md.png);
}

.icon-calendar.variation-secondary {
  background-image: url(1x/icon-calendar-secondary-lg.png);
}

.icon-calendar.variation-black {
  background-image: url(1x/icon-calendar-black-sm.png);
}

.icon-calendar.variation-black {
  background-image: url(1x/icon-calendar-black-md.png);
}

.icon-calendar.variation-black {
  background-image: url(1x/icon-calendar-black-lg.png);
}

.icon-calendar.variation-white {
  background-image: url(1x/icon-calendar-white-sm.png);
}

.icon-calendar.variation-white {
  background-image: url(1x/icon-calendar-white-md.png);
}

.icon-calendar.variation-white {
  background-image: url(1x/icon-calendar-white-lg.png);
}

.icon-calendar.variation-success {
  background-image: url(1x/icon-calendar-success-sm.png);
}

.icon-calendar.variation-success {
  background-image: url(1x/icon-calendar-success-md.png);
}

.icon-calendar.variation-success {
  background-image: url(1x/icon-calendar-success-lg.png);
}

.icon-calendar.variation-error {
  background-image: url(1x/icon-calendar-error-sm.png);
}

.icon-calendar.variation-error {
  background-image: url(1x/icon-calendar-error-md.png);
}

.icon-calendar.variation-error {
  background-image: url(1x/icon-calendar-error-lg.png);
}

.icon-calendar.variation-warning {
  background-image: url(1x/icon-calendar-warning-sm.png);
}

.icon-calendar.variation-warning {
  background-image: url(1x/icon-calendar-warning-md.png);
}

.icon-calendar.variation-warning {
  background-image: url(1x/icon-calendar-warning-lg.png);
}

.icon-camera.variation-primary {
  background-image: url(1x/icon-camera-primary-sm.png);
}

.icon-camera.size-sm {
  width: 18px;
  height: 18px;
}

.icon-camera.variation-primary {
  background-image: url(1x/icon-camera-primary-md.png);
}

.icon-camera.size-md {
  width: 21px;
  height: 21px;
}

.icon-camera.variation-primary {
  background-image: url(1x/icon-camera-primary-lg.png);
}

.icon-camera.size-lg {
  width: 29px;
  height: 29px;
}

.icon-camera.variation-secondary {
  background-image: url(1x/icon-camera-secondary-sm.png);
}

.icon-camera.variation-secondary {
  background-image: url(1x/icon-camera-secondary-md.png);
}

.icon-camera.variation-secondary {
  background-image: url(1x/icon-camera-secondary-lg.png);
}

.icon-camera.variation-black {
  background-image: url(1x/icon-camera-black-sm.png);
}

.icon-camera.variation-black {
  background-image: url(1x/icon-camera-black-md.png);
}

.icon-camera.variation-black {
  background-image: url(1x/icon-camera-black-lg.png);
}

.icon-camera.variation-white {
  background-image: url(1x/icon-camera-white-sm.png);
}

.icon-camera.variation-white {
  background-image: url(1x/icon-camera-white-md.png);
}

.icon-camera.variation-white {
  background-image: url(1x/icon-camera-white-lg.png);
}

.icon-camera.variation-success {
  background-image: url(1x/icon-camera-success-sm.png);
}

.icon-camera.variation-success {
  background-image: url(1x/icon-camera-success-md.png);
}

.icon-camera.variation-success {
  background-image: url(1x/icon-camera-success-lg.png);
}

.icon-camera.variation-error {
  background-image: url(1x/icon-camera-error-sm.png);
}

.icon-camera.variation-error {
  background-image: url(1x/icon-camera-error-md.png);
}

.icon-camera.variation-error {
  background-image: url(1x/icon-camera-error-lg.png);
}

.icon-camera.variation-warning {
  background-image: url(1x/icon-camera-warning-sm.png);
}

.icon-camera.variation-warning {
  background-image: url(1x/icon-camera-warning-md.png);
}

.icon-camera.variation-warning {
  background-image: url(1x/icon-camera-warning-lg.png);
}

.icon-check.variation-primary {
  background-image: url(1x/icon-check-primary-sm.png);
}

.icon-check.size-sm {
  width: 18px;
  height: 18px;
}

.icon-check.variation-primary {
  background-image: url(1x/icon-check-primary-md.png);
}

.icon-check.size-md {
  width: 21px;
  height: 21px;
}

.icon-check.variation-primary {
  background-image: url(1x/icon-check-primary-lg.png);
}

.icon-check.size-lg {
  width: 29px;
  height: 29px;
}

.icon-check.variation-secondary {
  background-image: url(1x/icon-check-secondary-sm.png);
}

.icon-check.variation-secondary {
  background-image: url(1x/icon-check-secondary-md.png);
}

.icon-check.variation-secondary {
  background-image: url(1x/icon-check-secondary-lg.png);
}

.icon-check.variation-black {
  background-image: url(1x/icon-check-black-sm.png);
}

.icon-check.variation-black {
  background-image: url(1x/icon-check-black-md.png);
}

.icon-check.variation-black {
  background-image: url(1x/icon-check-black-lg.png);
}

.icon-check.variation-white {
  background-image: url(1x/icon-check-white-sm.png);
}

.icon-check.variation-white {
  background-image: url(1x/icon-check-white-md.png);
}

.icon-check.variation-white {
  background-image: url(1x/icon-check-white-lg.png);
}

.icon-check.variation-success {
  background-image: url(1x/icon-check-success-sm.png);
}

.icon-check.variation-success {
  background-image: url(1x/icon-check-success-md.png);
}

.icon-check.variation-success {
  background-image: url(1x/icon-check-success-lg.png);
}

.icon-check.variation-error {
  background-image: url(1x/icon-check-error-sm.png);
}

.icon-check.variation-error {
  background-image: url(1x/icon-check-error-md.png);
}

.icon-check.variation-error {
  background-image: url(1x/icon-check-error-lg.png);
}

.icon-check.variation-warning {
  background-image: url(1x/icon-check-warning-sm.png);
}

.icon-check.variation-warning {
  background-image: url(1x/icon-check-warning-md.png);
}

.icon-check.variation-warning {
  background-image: url(1x/icon-check-warning-lg.png);
}

.icon-checklist.variation-primary {
  background-image: url(1x/icon-checklist-primary-sm.png);
}

.icon-checklist.size-sm {
  width: 18px;
  height: 18px;
}

.icon-checklist.variation-primary {
  background-image: url(1x/icon-checklist-primary-md.png);
}

.icon-checklist.size-md {
  width: 21px;
  height: 21px;
}

.icon-checklist.variation-primary {
  background-image: url(1x/icon-checklist-primary-lg.png);
}

.icon-checklist.size-lg {
  width: 29px;
  height: 29px;
}

.icon-checklist.variation-secondary {
  background-image: url(1x/icon-checklist-secondary-sm.png);
}

.icon-checklist.variation-secondary {
  background-image: url(1x/icon-checklist-secondary-md.png);
}

.icon-checklist.variation-secondary {
  background-image: url(1x/icon-checklist-secondary-lg.png);
}

.icon-checklist.variation-black {
  background-image: url(1x/icon-checklist-black-sm.png);
}

.icon-checklist.variation-black {
  background-image: url(1x/icon-checklist-black-md.png);
}

.icon-checklist.variation-black {
  background-image: url(1x/icon-checklist-black-lg.png);
}

.icon-checklist.variation-white {
  background-image: url(1x/icon-checklist-white-sm.png);
}

.icon-checklist.variation-white {
  background-image: url(1x/icon-checklist-white-md.png);
}

.icon-checklist.variation-white {
  background-image: url(1x/icon-checklist-white-lg.png);
}

.icon-checklist.variation-success {
  background-image: url(1x/icon-checklist-success-sm.png);
}

.icon-checklist.variation-success {
  background-image: url(1x/icon-checklist-success-md.png);
}

.icon-checklist.variation-success {
  background-image: url(1x/icon-checklist-success-lg.png);
}

.icon-checklist.variation-error {
  background-image: url(1x/icon-checklist-error-sm.png);
}

.icon-checklist.variation-error {
  background-image: url(1x/icon-checklist-error-md.png);
}

.icon-checklist.variation-error {
  background-image: url(1x/icon-checklist-error-lg.png);
}

.icon-checklist.variation-warning {
  background-image: url(1x/icon-checklist-warning-sm.png);
}

.icon-checklist.variation-warning {
  background-image: url(1x/icon-checklist-warning-md.png);
}

.icon-checklist.variation-warning {
  background-image: url(1x/icon-checklist-warning-lg.png);
}

.icon-comment.variation-primary {
  background-image: url(1x/icon-comment-primary-sm.png);
}

.icon-comment.size-sm {
  width: 18px;
  height: 18px;
}

.icon-comment.variation-primary {
  background-image: url(1x/icon-comment-primary-md.png);
}

.icon-comment.size-md {
  width: 21px;
  height: 21px;
}

.icon-comment.variation-primary {
  background-image: url(1x/icon-comment-primary-lg.png);
}

.icon-comment.size-lg {
  width: 29px;
  height: 29px;
}

.icon-comment.variation-secondary {
  background-image: url(1x/icon-comment-secondary-sm.png);
}

.icon-comment.variation-secondary {
  background-image: url(1x/icon-comment-secondary-md.png);
}

.icon-comment.variation-secondary {
  background-image: url(1x/icon-comment-secondary-lg.png);
}

.icon-comment.variation-black {
  background-image: url(1x/icon-comment-black-sm.png);
}

.icon-comment.variation-black {
  background-image: url(1x/icon-comment-black-md.png);
}

.icon-comment.variation-black {
  background-image: url(1x/icon-comment-black-lg.png);
}

.icon-comment.variation-white {
  background-image: url(1x/icon-comment-white-sm.png);
}

.icon-comment.variation-white {
  background-image: url(1x/icon-comment-white-md.png);
}

.icon-comment.variation-white {
  background-image: url(1x/icon-comment-white-lg.png);
}

.icon-comment.variation-success {
  background-image: url(1x/icon-comment-success-sm.png);
}

.icon-comment.variation-success {
  background-image: url(1x/icon-comment-success-md.png);
}

.icon-comment.variation-success {
  background-image: url(1x/icon-comment-success-lg.png);
}

.icon-comment.variation-error {
  background-image: url(1x/icon-comment-error-sm.png);
}

.icon-comment.variation-error {
  background-image: url(1x/icon-comment-error-md.png);
}

.icon-comment.variation-error {
  background-image: url(1x/icon-comment-error-lg.png);
}

.icon-comment.variation-warning {
  background-image: url(1x/icon-comment-warning-sm.png);
}

.icon-comment.variation-warning {
  background-image: url(1x/icon-comment-warning-md.png);
}

.icon-comment.variation-warning {
  background-image: url(1x/icon-comment-warning-lg.png);
}

.icon-download_csv.variation-primary {
  background-image: url(1x/icon-download_csv-primary-sm.png);
}

.icon-download_csv.size-sm {
  width: 18px;
  height: 18px;
}

.icon-download_csv.variation-primary {
  background-image: url(1x/icon-download_csv-primary-md.png);
}

.icon-download_csv.size-md {
  width: 21px;
  height: 21px;
}

.icon-download_csv.variation-primary {
  background-image: url(1x/icon-download_csv-primary-lg.png);
}

.icon-download_csv.size-lg {
  width: 29px;
  height: 29px;
}

.icon-download_csv.variation-secondary {
  background-image: url(1x/icon-download_csv-secondary-sm.png);
}

.icon-download_csv.variation-secondary {
  background-image: url(1x/icon-download_csv-secondary-md.png);
}

.icon-download_csv.variation-secondary {
  background-image: url(1x/icon-download_csv-secondary-lg.png);
}

.icon-download_csv.variation-black {
  background-image: url(1x/icon-download_csv-black-sm.png);
}

.icon-download_csv.variation-black {
  background-image: url(1x/icon-download_csv-black-md.png);
}

.icon-download_csv.variation-black {
  background-image: url(1x/icon-download_csv-black-lg.png);
}

.icon-download_csv.variation-white {
  background-image: url(1x/icon-download_csv-white-sm.png);
}

.icon-download_csv.variation-white {
  background-image: url(1x/icon-download_csv-white-md.png);
}

.icon-download_csv.variation-white {
  background-image: url(1x/icon-download_csv-white-lg.png);
}

.icon-download_csv.variation-success {
  background-image: url(1x/icon-download_csv-success-sm.png);
}

.icon-download_csv.variation-success {
  background-image: url(1x/icon-download_csv-success-md.png);
}

.icon-download_csv.variation-success {
  background-image: url(1x/icon-download_csv-success-lg.png);
}

.icon-download_csv.variation-error {
  background-image: url(1x/icon-download_csv-error-sm.png);
}

.icon-download_csv.variation-error {
  background-image: url(1x/icon-download_csv-error-md.png);
}

.icon-download_csv.variation-error {
  background-image: url(1x/icon-download_csv-error-lg.png);
}

.icon-download_csv.variation-warning {
  background-image: url(1x/icon-download_csv-warning-sm.png);
}

.icon-download_csv.variation-warning {
  background-image: url(1x/icon-download_csv-warning-md.png);
}

.icon-download_csv.variation-warning {
  background-image: url(1x/icon-download_csv-warning-lg.png);
}

.icon-download.variation-primary {
  background-image: url(1x/icon-download-primary-sm.png);
}

.icon-download.size-sm {
  width: 18px;
  height: 18px;
}

.icon-download.variation-primary {
  background-image: url(1x/icon-download-primary-md.png);
}

.icon-download.size-md {
  width: 21px;
  height: 21px;
}

.icon-download.variation-primary {
  background-image: url(1x/icon-download-primary-lg.png);
}

.icon-download.size-lg {
  width: 29px;
  height: 29px;
}

.icon-download.variation-secondary {
  background-image: url(1x/icon-download-secondary-sm.png);
}

.icon-download.variation-secondary {
  background-image: url(1x/icon-download-secondary-md.png);
}

.icon-download.variation-secondary {
  background-image: url(1x/icon-download-secondary-lg.png);
}

.icon-download.variation-black {
  background-image: url(1x/icon-download-black-sm.png);
}

.icon-download.variation-black {
  background-image: url(1x/icon-download-black-md.png);
}

.icon-download.variation-black {
  background-image: url(1x/icon-download-black-lg.png);
}

.icon-download.variation-white {
  background-image: url(1x/icon-download-white-sm.png);
}

.icon-download.variation-white {
  background-image: url(1x/icon-download-white-md.png);
}

.icon-download.variation-white {
  background-image: url(1x/icon-download-white-lg.png);
}

.icon-download.variation-success {
  background-image: url(1x/icon-download-success-sm.png);
}

.icon-download.variation-success {
  background-image: url(1x/icon-download-success-md.png);
}

.icon-download.variation-success {
  background-image: url(1x/icon-download-success-lg.png);
}

.icon-download.variation-error {
  background-image: url(1x/icon-download-error-sm.png);
}

.icon-download.variation-error {
  background-image: url(1x/icon-download-error-md.png);
}

.icon-download.variation-error {
  background-image: url(1x/icon-download-error-lg.png);
}

.icon-download.variation-warning {
  background-image: url(1x/icon-download-warning-sm.png);
}

.icon-download.variation-warning {
  background-image: url(1x/icon-download-warning-md.png);
}

.icon-download.variation-warning {
  background-image: url(1x/icon-download-warning-lg.png);
}

.icon-earning.variation-primary {
  background-image: url(1x/icon-earning-primary-sm.png);
}

.icon-earning.size-sm {
  width: 18px;
  height: 18px;
}

.icon-earning.variation-primary {
  background-image: url(1x/icon-earning-primary-md.png);
}

.icon-earning.size-md {
  width: 21px;
  height: 21px;
}

.icon-earning.variation-primary {
  background-image: url(1x/icon-earning-primary-lg.png);
}

.icon-earning.size-lg {
  width: 29px;
  height: 29px;
}

.icon-earning.variation-secondary {
  background-image: url(1x/icon-earning-secondary-sm.png);
}

.icon-earning.variation-secondary {
  background-image: url(1x/icon-earning-secondary-md.png);
}

.icon-earning.variation-secondary {
  background-image: url(1x/icon-earning-secondary-lg.png);
}

.icon-earning.variation-black {
  background-image: url(1x/icon-earning-black-sm.png);
}

.icon-earning.variation-black {
  background-image: url(1x/icon-earning-black-md.png);
}

.icon-earning.variation-black {
  background-image: url(1x/icon-earning-black-lg.png);
}

.icon-earning.variation-white {
  background-image: url(1x/icon-earning-white-sm.png);
}

.icon-earning.variation-white {
  background-image: url(1x/icon-earning-white-md.png);
}

.icon-earning.variation-white {
  background-image: url(1x/icon-earning-white-lg.png);
}

.icon-earning.variation-success {
  background-image: url(1x/icon-earning-success-sm.png);
}

.icon-earning.variation-success {
  background-image: url(1x/icon-earning-success-md.png);
}

.icon-earning.variation-success {
  background-image: url(1x/icon-earning-success-lg.png);
}

.icon-earning.variation-error {
  background-image: url(1x/icon-earning-error-sm.png);
}

.icon-earning.variation-error {
  background-image: url(1x/icon-earning-error-md.png);
}

.icon-earning.variation-error {
  background-image: url(1x/icon-earning-error-lg.png);
}

.icon-earning.variation-warning {
  background-image: url(1x/icon-earning-warning-sm.png);
}

.icon-earning.variation-warning {
  background-image: url(1x/icon-earning-warning-md.png);
}

.icon-earning.variation-warning {
  background-image: url(1x/icon-earning-warning-lg.png);
}

.icon-edit_date.variation-primary {
  background-image: url(1x/icon-edit_date-primary-sm.png);
}

.icon-edit_date.size-sm {
  width: 18px;
  height: 18px;
}

.icon-edit_date.variation-primary {
  background-image: url(1x/icon-edit_date-primary-md.png);
}

.icon-edit_date.size-md {
  width: 21px;
  height: 21px;
}

.icon-edit_date.variation-primary {
  background-image: url(1x/icon-edit_date-primary-lg.png);
}

.icon-edit_date.size-lg {
  width: 29px;
  height: 29px;
}

.icon-edit_date.variation-secondary {
  background-image: url(1x/icon-edit_date-secondary-sm.png);
}

.icon-edit_date.variation-secondary {
  background-image: url(1x/icon-edit_date-secondary-md.png);
}

.icon-edit_date.variation-secondary {
  background-image: url(1x/icon-edit_date-secondary-lg.png);
}

.icon-edit_date.variation-black {
  background-image: url(1x/icon-edit_date-black-sm.png);
}

.icon-edit_date.variation-black {
  background-image: url(1x/icon-edit_date-black-md.png);
}

.icon-edit_date.variation-black {
  background-image: url(1x/icon-edit_date-black-lg.png);
}

.icon-edit_date.variation-white {
  background-image: url(1x/icon-edit_date-white-sm.png);
}

.icon-edit_date.variation-white {
  background-image: url(1x/icon-edit_date-white-md.png);
}

.icon-edit_date.variation-white {
  background-image: url(1x/icon-edit_date-white-lg.png);
}

.icon-edit_date.variation-success {
  background-image: url(1x/icon-edit_date-success-sm.png);
}

.icon-edit_date.variation-success {
  background-image: url(1x/icon-edit_date-success-md.png);
}

.icon-edit_date.variation-success {
  background-image: url(1x/icon-edit_date-success-lg.png);
}

.icon-edit_date.variation-error {
  background-image: url(1x/icon-edit_date-error-sm.png);
}

.icon-edit_date.variation-error {
  background-image: url(1x/icon-edit_date-error-md.png);
}

.icon-edit_date.variation-error {
  background-image: url(1x/icon-edit_date-error-lg.png);
}

.icon-edit_date.variation-warning {
  background-image: url(1x/icon-edit_date-warning-sm.png);
}

.icon-edit_date.variation-warning {
  background-image: url(1x/icon-edit_date-warning-md.png);
}

.icon-edit_date.variation-warning {
  background-image: url(1x/icon-edit_date-warning-lg.png);
}

.icon-edit.variation-primary {
  background-image: url(1x/icon-edit-primary-sm.png);
}

.icon-edit.size-sm {
  width: 18px;
  height: 18px;
}

.icon-edit.variation-primary {
  background-image: url(1x/icon-edit-primary-md.png);
}

.icon-edit.size-md {
  width: 21px;
  height: 21px;
}

.icon-edit.variation-primary {
  background-image: url(1x/icon-edit-primary-lg.png);
}

.icon-edit.size-lg {
  width: 29px;
  height: 29px;
}

.icon-edit.variation-secondary {
  background-image: url(1x/icon-edit-secondary-sm.png);
}

.icon-edit.variation-secondary {
  background-image: url(1x/icon-edit-secondary-md.png);
}

.icon-edit.variation-secondary {
  background-image: url(1x/icon-edit-secondary-lg.png);
}

.icon-edit.variation-black {
  background-image: url(1x/icon-edit-black-sm.png);
}

.icon-edit.variation-black {
  background-image: url(1x/icon-edit-black-md.png);
}

.icon-edit.variation-black {
  background-image: url(1x/icon-edit-black-lg.png);
}

.icon-edit.variation-white {
  background-image: url(1x/icon-edit-white-sm.png);
}

.icon-edit.variation-white {
  background-image: url(1x/icon-edit-white-md.png);
}

.icon-edit.variation-white {
  background-image: url(1x/icon-edit-white-lg.png);
}

.icon-edit.variation-success {
  background-image: url(1x/icon-edit-success-sm.png);
}

.icon-edit.variation-success {
  background-image: url(1x/icon-edit-success-md.png);
}

.icon-edit.variation-success {
  background-image: url(1x/icon-edit-success-lg.png);
}

.icon-edit.variation-error {
  background-image: url(1x/icon-edit-error-sm.png);
}

.icon-edit.variation-error {
  background-image: url(1x/icon-edit-error-md.png);
}

.icon-edit.variation-error {
  background-image: url(1x/icon-edit-error-lg.png);
}

.icon-edit.variation-warning {
  background-image: url(1x/icon-edit-warning-sm.png);
}

.icon-edit.variation-warning {
  background-image: url(1x/icon-edit-warning-md.png);
}

.icon-edit.variation-warning {
  background-image: url(1x/icon-edit-warning-lg.png);
}

.icon-equipment.variation-primary {
  background-image: url(1x/icon-equipment-primary-sm.png);
}

.icon-equipment.size-sm {
  width: 18px;
  height: 18px;
}

.icon-equipment.variation-primary {
  background-image: url(1x/icon-equipment-primary-md.png);
}

.icon-equipment.size-md {
  width: 21px;
  height: 21px;
}

.icon-equipment.variation-primary {
  background-image: url(1x/icon-equipment-primary-lg.png);
}

.icon-equipment.size-lg {
  width: 29px;
  height: 29px;
}

.icon-equipment.variation-secondary {
  background-image: url(1x/icon-equipment-secondary-sm.png);
}

.icon-equipment.variation-secondary {
  background-image: url(1x/icon-equipment-secondary-md.png);
}

.icon-equipment.variation-secondary {
  background-image: url(1x/icon-equipment-secondary-lg.png);
}

.icon-equipment.variation-black {
  background-image: url(1x/icon-equipment-black-sm.png);
}

.icon-equipment.variation-black {
  background-image: url(1x/icon-equipment-black-md.png);
}

.icon-equipment.variation-black {
  background-image: url(1x/icon-equipment-black-lg.png);
}

.icon-equipment.variation-white {
  background-image: url(1x/icon-equipment-white-sm.png);
}

.icon-equipment.variation-white {
  background-image: url(1x/icon-equipment-white-md.png);
}

.icon-equipment.variation-white {
  background-image: url(1x/icon-equipment-white-lg.png);
}

.icon-equipment.variation-success {
  background-image: url(1x/icon-equipment-success-sm.png);
}

.icon-equipment.variation-success {
  background-image: url(1x/icon-equipment-success-md.png);
}

.icon-equipment.variation-success {
  background-image: url(1x/icon-equipment-success-lg.png);
}

.icon-equipment.variation-error {
  background-image: url(1x/icon-equipment-error-sm.png);
}

.icon-equipment.variation-error {
  background-image: url(1x/icon-equipment-error-md.png);
}

.icon-equipment.variation-error {
  background-image: url(1x/icon-equipment-error-lg.png);
}

.icon-equipment.variation-warning {
  background-image: url(1x/icon-equipment-warning-sm.png);
}

.icon-equipment.variation-warning {
  background-image: url(1x/icon-equipment-warning-md.png);
}

.icon-equipment.variation-warning {
  background-image: url(1x/icon-equipment-warning-lg.png);
}

.icon-error.variation-primary {
  background-image: url(1x/icon-error-primary-sm.png);
}

.icon-error.size-sm {
  width: 18px;
  height: 18px;
}

.icon-error.variation-primary {
  background-image: url(1x/icon-error-primary-md.png);
}

.icon-error.size-md {
  width: 21px;
  height: 21px;
}

.icon-error.variation-primary {
  background-image: url(1x/icon-error-primary-lg.png);
}

.icon-error.size-lg {
  width: 29px;
  height: 29px;
}

.icon-error.variation-secondary {
  background-image: url(1x/icon-error-secondary-sm.png);
}

.icon-error.variation-secondary {
  background-image: url(1x/icon-error-secondary-md.png);
}

.icon-error.variation-secondary {
  background-image: url(1x/icon-error-secondary-lg.png);
}

.icon-error.variation-black {
  background-image: url(1x/icon-error-black-sm.png);
}

.icon-error.variation-black {
  background-image: url(1x/icon-error-black-md.png);
}

.icon-error.variation-black {
  background-image: url(1x/icon-error-black-lg.png);
}

.icon-error.variation-white {
  background-image: url(1x/icon-error-white-sm.png);
}

.icon-error.variation-white {
  background-image: url(1x/icon-error-white-md.png);
}

.icon-error.variation-white {
  background-image: url(1x/icon-error-white-lg.png);
}

.icon-error.variation-success {
  background-image: url(1x/icon-error-success-sm.png);
}

.icon-error.variation-success {
  background-image: url(1x/icon-error-success-md.png);
}

.icon-error.variation-success {
  background-image: url(1x/icon-error-success-lg.png);
}

.icon-error.variation-error {
  background-image: url(1x/icon-error-error-sm.png);
}

.icon-error.variation-error {
  background-image: url(1x/icon-error-error-md.png);
}

.icon-error.variation-error {
  background-image: url(1x/icon-error-error-lg.png);
}

.icon-error.variation-warning {
  background-image: url(1x/icon-error-warning-sm.png);
}

.icon-error.variation-warning {
  background-image: url(1x/icon-error-warning-md.png);
}

.icon-error.variation-warning {
  background-image: url(1x/icon-error-warning-lg.png);
}

.icon-growth.variation-primary {
  background-image: url(1x/icon-growth-primary-sm.png);
}

.icon-growth.size-sm {
  width: 18px;
  height: 18px;
}

.icon-growth.variation-primary {
  background-image: url(1x/icon-growth-primary-md.png);
}

.icon-growth.size-md {
  width: 21px;
  height: 21px;
}

.icon-growth.variation-primary {
  background-image: url(1x/icon-growth-primary-lg.png);
}

.icon-growth.size-lg {
  width: 29px;
  height: 29px;
}

.icon-growth.variation-secondary {
  background-image: url(1x/icon-growth-secondary-sm.png);
}

.icon-growth.variation-secondary {
  background-image: url(1x/icon-growth-secondary-md.png);
}

.icon-growth.variation-secondary {
  background-image: url(1x/icon-growth-secondary-lg.png);
}

.icon-growth.variation-black {
  background-image: url(1x/icon-growth-black-sm.png);
}

.icon-growth.variation-black {
  background-image: url(1x/icon-growth-black-md.png);
}

.icon-growth.variation-black {
  background-image: url(1x/icon-growth-black-lg.png);
}

.icon-growth.variation-white {
  background-image: url(1x/icon-growth-white-sm.png);
}

.icon-growth.variation-white {
  background-image: url(1x/icon-growth-white-md.png);
}

.icon-growth.variation-white {
  background-image: url(1x/icon-growth-white-lg.png);
}

.icon-growth.variation-success {
  background-image: url(1x/icon-growth-success-sm.png);
}

.icon-growth.variation-success {
  background-image: url(1x/icon-growth-success-md.png);
}

.icon-growth.variation-success {
  background-image: url(1x/icon-growth-success-lg.png);
}

.icon-growth.variation-error {
  background-image: url(1x/icon-growth-error-sm.png);
}

.icon-growth.variation-error {
  background-image: url(1x/icon-growth-error-md.png);
}

.icon-growth.variation-error {
  background-image: url(1x/icon-growth-error-lg.png);
}

.icon-growth.variation-warning {
  background-image: url(1x/icon-growth-warning-sm.png);
}

.icon-growth.variation-warning {
  background-image: url(1x/icon-growth-warning-md.png);
}

.icon-growth.variation-warning {
  background-image: url(1x/icon-growth-warning-lg.png);
}

.icon-guidelines.variation-primary {
  background-image: url(1x/icon-guidelines-primary-sm.png);
}

.icon-guidelines.size-sm {
  width: 18px;
  height: 18px;
}

.icon-guidelines.variation-primary {
  background-image: url(1x/icon-guidelines-primary-md.png);
}

.icon-guidelines.size-md {
  width: 21px;
  height: 21px;
}

.icon-guidelines.variation-primary {
  background-image: url(1x/icon-guidelines-primary-lg.png);
}

.icon-guidelines.size-lg {
  width: 29px;
  height: 29px;
}

.icon-guidelines.variation-secondary {
  background-image: url(1x/icon-guidelines-secondary-sm.png);
}

.icon-guidelines.variation-secondary {
  background-image: url(1x/icon-guidelines-secondary-md.png);
}

.icon-guidelines.variation-secondary {
  background-image: url(1x/icon-guidelines-secondary-lg.png);
}

.icon-guidelines.variation-black {
  background-image: url(1x/icon-guidelines-black-sm.png);
}

.icon-guidelines.variation-black {
  background-image: url(1x/icon-guidelines-black-md.png);
}

.icon-guidelines.variation-black {
  background-image: url(1x/icon-guidelines-black-lg.png);
}

.icon-guidelines.variation-white {
  background-image: url(1x/icon-guidelines-white-sm.png);
}

.icon-guidelines.variation-white {
  background-image: url(1x/icon-guidelines-white-md.png);
}

.icon-guidelines.variation-white {
  background-image: url(1x/icon-guidelines-white-lg.png);
}

.icon-guidelines.variation-success {
  background-image: url(1x/icon-guidelines-success-sm.png);
}

.icon-guidelines.variation-success {
  background-image: url(1x/icon-guidelines-success-md.png);
}

.icon-guidelines.variation-success {
  background-image: url(1x/icon-guidelines-success-lg.png);
}

.icon-guidelines.variation-error {
  background-image: url(1x/icon-guidelines-error-sm.png);
}

.icon-guidelines.variation-error {
  background-image: url(1x/icon-guidelines-error-md.png);
}

.icon-guidelines.variation-error {
  background-image: url(1x/icon-guidelines-error-lg.png);
}

.icon-guidelines.variation-warning {
  background-image: url(1x/icon-guidelines-warning-sm.png);
}

.icon-guidelines.variation-warning {
  background-image: url(1x/icon-guidelines-warning-md.png);
}

.icon-guidelines.variation-warning {
  background-image: url(1x/icon-guidelines-warning-lg.png);
}

.icon-house.variation-primary {
  background-image: url(1x/icon-house-primary-sm.png);
}

.icon-house.size-sm {
  width: 18px;
  height: 18px;
}

.icon-house.variation-primary {
  background-image: url(1x/icon-house-primary-md.png);
}

.icon-house.size-md {
  width: 21px;
  height: 21px;
}

.icon-house.variation-primary {
  background-image: url(1x/icon-house-primary-lg.png);
}

.icon-house.size-lg {
  width: 29px;
  height: 29px;
}

.icon-house.variation-secondary {
  background-image: url(1x/icon-house-secondary-sm.png);
}

.icon-house.variation-secondary {
  background-image: url(1x/icon-house-secondary-md.png);
}

.icon-house.variation-secondary {
  background-image: url(1x/icon-house-secondary-lg.png);
}

.icon-house.variation-black {
  background-image: url(1x/icon-house-black-sm.png);
}

.icon-house.variation-black {
  background-image: url(1x/icon-house-black-md.png);
}

.icon-house.variation-black {
  background-image: url(1x/icon-house-black-lg.png);
}

.icon-house.variation-white {
  background-image: url(1x/icon-house-white-sm.png);
}

.icon-house.variation-white {
  background-image: url(1x/icon-house-white-md.png);
}

.icon-house.variation-white {
  background-image: url(1x/icon-house-white-lg.png);
}

.icon-house.variation-success {
  background-image: url(1x/icon-house-success-sm.png);
}

.icon-house.variation-success {
  background-image: url(1x/icon-house-success-md.png);
}

.icon-house.variation-success {
  background-image: url(1x/icon-house-success-lg.png);
}

.icon-house.variation-error {
  background-image: url(1x/icon-house-error-sm.png);
}

.icon-house.variation-error {
  background-image: url(1x/icon-house-error-md.png);
}

.icon-house.variation-error {
  background-image: url(1x/icon-house-error-lg.png);
}

.icon-house.variation-warning {
  background-image: url(1x/icon-house-warning-sm.png);
}

.icon-house.variation-warning {
  background-image: url(1x/icon-house-warning-md.png);
}

.icon-house.variation-warning {
  background-image: url(1x/icon-house-warning-lg.png);
}

.icon-id.variation-primary {
  background-image: url(1x/icon-id-primary-sm.png);
}

.icon-id.size-sm {
  width: 18px;
  height: 18px;
}

.icon-id.variation-primary {
  background-image: url(1x/icon-id-primary-md.png);
}

.icon-id.size-md {
  width: 21px;
  height: 21px;
}

.icon-id.variation-primary {
  background-image: url(1x/icon-id-primary-lg.png);
}

.icon-id.size-lg {
  width: 29px;
  height: 29px;
}

.icon-id.variation-secondary {
  background-image: url(1x/icon-id-secondary-sm.png);
}

.icon-id.variation-secondary {
  background-image: url(1x/icon-id-secondary-md.png);
}

.icon-id.variation-secondary {
  background-image: url(1x/icon-id-secondary-lg.png);
}

.icon-id.variation-black {
  background-image: url(1x/icon-id-black-sm.png);
}

.icon-id.variation-black {
  background-image: url(1x/icon-id-black-md.png);
}

.icon-id.variation-black {
  background-image: url(1x/icon-id-black-lg.png);
}

.icon-id.variation-white {
  background-image: url(1x/icon-id-white-sm.png);
}

.icon-id.variation-white {
  background-image: url(1x/icon-id-white-md.png);
}

.icon-id.variation-white {
  background-image: url(1x/icon-id-white-lg.png);
}

.icon-id.variation-success {
  background-image: url(1x/icon-id-success-sm.png);
}

.icon-id.variation-success {
  background-image: url(1x/icon-id-success-md.png);
}

.icon-id.variation-success {
  background-image: url(1x/icon-id-success-lg.png);
}

.icon-id.variation-error {
  background-image: url(1x/icon-id-error-sm.png);
}

.icon-id.variation-error {
  background-image: url(1x/icon-id-error-md.png);
}

.icon-id.variation-error {
  background-image: url(1x/icon-id-error-lg.png);
}

.icon-id.variation-warning {
  background-image: url(1x/icon-id-warning-sm.png);
}

.icon-id.variation-warning {
  background-image: url(1x/icon-id-warning-md.png);
}

.icon-id.variation-warning {
  background-image: url(1x/icon-id-warning-lg.png);
}

.icon-idea.variation-primary {
  background-image: url(1x/icon-idea-primary-sm.png);
}

.icon-idea.size-sm {
  width: 18px;
  height: 18px;
}

.icon-idea.variation-primary {
  background-image: url(1x/icon-idea-primary-md.png);
}

.icon-idea.size-md {
  width: 21px;
  height: 21px;
}

.icon-idea.variation-primary {
  background-image: url(1x/icon-idea-primary-lg.png);
}

.icon-idea.size-lg {
  width: 29px;
  height: 29px;
}

.icon-idea.variation-secondary {
  background-image: url(1x/icon-idea-secondary-sm.png);
}

.icon-idea.variation-secondary {
  background-image: url(1x/icon-idea-secondary-md.png);
}

.icon-idea.variation-secondary {
  background-image: url(1x/icon-idea-secondary-lg.png);
}

.icon-idea.variation-black {
  background-image: url(1x/icon-idea-black-sm.png);
}

.icon-idea.variation-black {
  background-image: url(1x/icon-idea-black-md.png);
}

.icon-idea.variation-black {
  background-image: url(1x/icon-idea-black-lg.png);
}

.icon-idea.variation-white {
  background-image: url(1x/icon-idea-white-sm.png);
}

.icon-idea.variation-white {
  background-image: url(1x/icon-idea-white-md.png);
}

.icon-idea.variation-white {
  background-image: url(1x/icon-idea-white-lg.png);
}

.icon-idea.variation-success {
  background-image: url(1x/icon-idea-success-sm.png);
}

.icon-idea.variation-success {
  background-image: url(1x/icon-idea-success-md.png);
}

.icon-idea.variation-success {
  background-image: url(1x/icon-idea-success-lg.png);
}

.icon-idea.variation-error {
  background-image: url(1x/icon-idea-error-sm.png);
}

.icon-idea.variation-error {
  background-image: url(1x/icon-idea-error-md.png);
}

.icon-idea.variation-error {
  background-image: url(1x/icon-idea-error-lg.png);
}

.icon-idea.variation-warning {
  background-image: url(1x/icon-idea-warning-sm.png);
}

.icon-idea.variation-warning {
  background-image: url(1x/icon-idea-warning-md.png);
}

.icon-idea.variation-warning {
  background-image: url(1x/icon-idea-warning-lg.png);
}

.icon-impact.variation-primary {
  background-image: url(1x/icon-impact-primary-sm.png);
}

.icon-impact.size-sm {
  width: 18px;
  height: 18px;
}

.icon-impact.variation-primary {
  background-image: url(1x/icon-impact-primary-md.png);
}

.icon-impact.size-md {
  width: 21px;
  height: 21px;
}

.icon-impact.variation-primary {
  background-image: url(1x/icon-impact-primary-lg.png);
}

.icon-impact.size-lg {
  width: 29px;
  height: 29px;
}

.icon-impact.variation-secondary {
  background-image: url(1x/icon-impact-secondary-sm.png);
}

.icon-impact.variation-secondary {
  background-image: url(1x/icon-impact-secondary-md.png);
}

.icon-impact.variation-secondary {
  background-image: url(1x/icon-impact-secondary-lg.png);
}

.icon-impact.variation-black {
  background-image: url(1x/icon-impact-black-sm.png);
}

.icon-impact.variation-black {
  background-image: url(1x/icon-impact-black-md.png);
}

.icon-impact.variation-black {
  background-image: url(1x/icon-impact-black-lg.png);
}

.icon-impact.variation-white {
  background-image: url(1x/icon-impact-white-sm.png);
}

.icon-impact.variation-white {
  background-image: url(1x/icon-impact-white-md.png);
}

.icon-impact.variation-white {
  background-image: url(1x/icon-impact-white-lg.png);
}

.icon-impact.variation-success {
  background-image: url(1x/icon-impact-success-sm.png);
}

.icon-impact.variation-success {
  background-image: url(1x/icon-impact-success-md.png);
}

.icon-impact.variation-success {
  background-image: url(1x/icon-impact-success-lg.png);
}

.icon-impact.variation-error {
  background-image: url(1x/icon-impact-error-sm.png);
}

.icon-impact.variation-error {
  background-image: url(1x/icon-impact-error-md.png);
}

.icon-impact.variation-error {
  background-image: url(1x/icon-impact-error-lg.png);
}

.icon-impact.variation-warning {
  background-image: url(1x/icon-impact-warning-sm.png);
}

.icon-impact.variation-warning {
  background-image: url(1x/icon-impact-warning-md.png);
}

.icon-impact.variation-warning {
  background-image: url(1x/icon-impact-warning-lg.png);
}

.icon-lemon.variation-primary {
  background-image: url(1x/icon-lemon-primary-sm.png);
}

.icon-lemon.size-sm {
  width: 18px;
  height: 18px;
}

.icon-lemon.variation-primary {
  background-image: url(1x/icon-lemon-primary-md.png);
}

.icon-lemon.size-md {
  width: 21px;
  height: 21px;
}

.icon-lemon.variation-primary {
  background-image: url(1x/icon-lemon-primary-lg.png);
}

.icon-lemon.size-lg {
  width: 29px;
  height: 29px;
}

.icon-lemon.variation-secondary {
  background-image: url(1x/icon-lemon-secondary-sm.png);
}

.icon-lemon.variation-secondary {
  background-image: url(1x/icon-lemon-secondary-md.png);
}

.icon-lemon.variation-secondary {
  background-image: url(1x/icon-lemon-secondary-lg.png);
}

.icon-lemon.variation-black {
  background-image: url(1x/icon-lemon-black-sm.png);
}

.icon-lemon.variation-black {
  background-image: url(1x/icon-lemon-black-md.png);
}

.icon-lemon.variation-black {
  background-image: url(1x/icon-lemon-black-lg.png);
}

.icon-lemon.variation-white {
  background-image: url(1x/icon-lemon-white-sm.png);
}

.icon-lemon.variation-white {
  background-image: url(1x/icon-lemon-white-md.png);
}

.icon-lemon.variation-white {
  background-image: url(1x/icon-lemon-white-lg.png);
}

.icon-lemon.variation-success {
  background-image: url(1x/icon-lemon-success-sm.png);
}

.icon-lemon.variation-success {
  background-image: url(1x/icon-lemon-success-md.png);
}

.icon-lemon.variation-success {
  background-image: url(1x/icon-lemon-success-lg.png);
}

.icon-lemon.variation-error {
  background-image: url(1x/icon-lemon-error-sm.png);
}

.icon-lemon.variation-error {
  background-image: url(1x/icon-lemon-error-md.png);
}

.icon-lemon.variation-error {
  background-image: url(1x/icon-lemon-error-lg.png);
}

.icon-lemon.variation-warning {
  background-image: url(1x/icon-lemon-warning-sm.png);
}

.icon-lemon.variation-warning {
  background-image: url(1x/icon-lemon-warning-md.png);
}

.icon-lemon.variation-warning {
  background-image: url(1x/icon-lemon-warning-lg.png);
}

.icon-location.variation-primary {
  background-image: url(1x/icon-location-primary-sm.png);
}

.icon-location.size-sm {
  width: 18px;
  height: 18px;
}

.icon-location.variation-primary {
  background-image: url(1x/icon-location-primary-md.png);
}

.icon-location.size-md {
  width: 21px;
  height: 21px;
}

.icon-location.variation-primary {
  background-image: url(1x/icon-location-primary-lg.png);
}

.icon-location.size-lg {
  width: 29px;
  height: 29px;
}

.icon-location.variation-secondary {
  background-image: url(1x/icon-location-secondary-sm.png);
}

.icon-location.variation-secondary {
  background-image: url(1x/icon-location-secondary-md.png);
}

.icon-location.variation-secondary {
  background-image: url(1x/icon-location-secondary-lg.png);
}

.icon-location.variation-black {
  background-image: url(1x/icon-location-black-sm.png);
}

.icon-location.variation-black {
  background-image: url(1x/icon-location-black-md.png);
}

.icon-location.variation-black {
  background-image: url(1x/icon-location-black-lg.png);
}

.icon-location.variation-white {
  background-image: url(1x/icon-location-white-sm.png);
}

.icon-location.variation-white {
  background-image: url(1x/icon-location-white-md.png);
}

.icon-location.variation-white {
  background-image: url(1x/icon-location-white-lg.png);
}

.icon-location.variation-success {
  background-image: url(1x/icon-location-success-sm.png);
}

.icon-location.variation-success {
  background-image: url(1x/icon-location-success-md.png);
}

.icon-location.variation-success {
  background-image: url(1x/icon-location-success-lg.png);
}

.icon-location.variation-error {
  background-image: url(1x/icon-location-error-sm.png);
}

.icon-location.variation-error {
  background-image: url(1x/icon-location-error-md.png);
}

.icon-location.variation-error {
  background-image: url(1x/icon-location-error-lg.png);
}

.icon-location.variation-warning {
  background-image: url(1x/icon-location-warning-sm.png);
}

.icon-location.variation-warning {
  background-image: url(1x/icon-location-warning-md.png);
}

.icon-location.variation-warning {
  background-image: url(1x/icon-location-warning-lg.png);
}

.icon-log_out.variation-primary {
  background-image: url(1x/icon-log_out-primary-sm.png);
}

.icon-log_out.size-sm {
  width: 18px;
  height: 18px;
}

.icon-log_out.variation-primary {
  background-image: url(1x/icon-log_out-primary-md.png);
}

.icon-log_out.size-md {
  width: 21px;
  height: 21px;
}

.icon-log_out.variation-primary {
  background-image: url(1x/icon-log_out-primary-lg.png);
}

.icon-log_out.size-lg {
  width: 29px;
  height: 29px;
}

.icon-log_out.variation-secondary {
  background-image: url(1x/icon-log_out-secondary-sm.png);
}

.icon-log_out.variation-secondary {
  background-image: url(1x/icon-log_out-secondary-md.png);
}

.icon-log_out.variation-secondary {
  background-image: url(1x/icon-log_out-secondary-lg.png);
}

.icon-log_out.variation-black {
  background-image: url(1x/icon-log_out-black-sm.png);
}

.icon-log_out.variation-black {
  background-image: url(1x/icon-log_out-black-md.png);
}

.icon-log_out.variation-black {
  background-image: url(1x/icon-log_out-black-lg.png);
}

.icon-log_out.variation-white {
  background-image: url(1x/icon-log_out-white-sm.png);
}

.icon-log_out.variation-white {
  background-image: url(1x/icon-log_out-white-md.png);
}

.icon-log_out.variation-white {
  background-image: url(1x/icon-log_out-white-lg.png);
}

.icon-log_out.variation-success {
  background-image: url(1x/icon-log_out-success-sm.png);
}

.icon-log_out.variation-success {
  background-image: url(1x/icon-log_out-success-md.png);
}

.icon-log_out.variation-success {
  background-image: url(1x/icon-log_out-success-lg.png);
}

.icon-log_out.variation-error {
  background-image: url(1x/icon-log_out-error-sm.png);
}

.icon-log_out.variation-error {
  background-image: url(1x/icon-log_out-error-md.png);
}

.icon-log_out.variation-error {
  background-image: url(1x/icon-log_out-error-lg.png);
}

.icon-log_out.variation-warning {
  background-image: url(1x/icon-log_out-warning-sm.png);
}

.icon-log_out.variation-warning {
  background-image: url(1x/icon-log_out-warning-md.png);
}

.icon-log_out.variation-warning {
  background-image: url(1x/icon-log_out-warning-lg.png);
}

.icon-message.variation-primary {
  background-image: url(1x/icon-message-primary-sm.png);
}

.icon-message.size-sm {
  width: 18px;
  height: 18px;
}

.icon-message.variation-primary {
  background-image: url(1x/icon-message-primary-md.png);
}

.icon-message.size-md {
  width: 21px;
  height: 21px;
}

.icon-message.variation-primary {
  background-image: url(1x/icon-message-primary-lg.png);
}

.icon-message.size-lg {
  width: 29px;
  height: 29px;
}

.icon-message.variation-secondary {
  background-image: url(1x/icon-message-secondary-sm.png);
}

.icon-message.variation-secondary {
  background-image: url(1x/icon-message-secondary-md.png);
}

.icon-message.variation-secondary {
  background-image: url(1x/icon-message-secondary-lg.png);
}

.icon-message.variation-black {
  background-image: url(1x/icon-message-black-sm.png);
}

.icon-message.variation-black {
  background-image: url(1x/icon-message-black-md.png);
}

.icon-message.variation-black {
  background-image: url(1x/icon-message-black-lg.png);
}

.icon-message.variation-white {
  background-image: url(1x/icon-message-white-sm.png);
}

.icon-message.variation-white {
  background-image: url(1x/icon-message-white-md.png);
}

.icon-message.variation-white {
  background-image: url(1x/icon-message-white-lg.png);
}

.icon-message.variation-success {
  background-image: url(1x/icon-message-success-sm.png);
}

.icon-message.variation-success {
  background-image: url(1x/icon-message-success-md.png);
}

.icon-message.variation-success {
  background-image: url(1x/icon-message-success-lg.png);
}

.icon-message.variation-error {
  background-image: url(1x/icon-message-error-sm.png);
}

.icon-message.variation-error {
  background-image: url(1x/icon-message-error-md.png);
}

.icon-message.variation-error {
  background-image: url(1x/icon-message-error-lg.png);
}

.icon-message.variation-warning {
  background-image: url(1x/icon-message-warning-sm.png);
}

.icon-message.variation-warning {
  background-image: url(1x/icon-message-warning-md.png);
}

.icon-message.variation-warning {
  background-image: url(1x/icon-message-warning-lg.png);
}

.icon-missing_photoshoot.variation-primary {
  background-image: url(1x/icon-missing_photoshoot-primary-sm.png);
}

.icon-missing_photoshoot.size-sm {
  width: 18px;
  height: 18px;
}

.icon-missing_photoshoot.variation-primary {
  background-image: url(1x/icon-missing_photoshoot-primary-md.png);
}

.icon-missing_photoshoot.size-md {
  width: 21px;
  height: 21px;
}

.icon-missing_photoshoot.variation-primary {
  background-image: url(1x/icon-missing_photoshoot-primary-lg.png);
}

.icon-missing_photoshoot.size-lg {
  width: 29px;
  height: 29px;
}

.icon-missing_photoshoot.variation-secondary {
  background-image: url(1x/icon-missing_photoshoot-secondary-sm.png);
}

.icon-missing_photoshoot.variation-secondary {
  background-image: url(1x/icon-missing_photoshoot-secondary-md.png);
}

.icon-missing_photoshoot.variation-secondary {
  background-image: url(1x/icon-missing_photoshoot-secondary-lg.png);
}

.icon-missing_photoshoot.variation-black {
  background-image: url(1x/icon-missing_photoshoot-black-sm.png);
}

.icon-missing_photoshoot.variation-black {
  background-image: url(1x/icon-missing_photoshoot-black-md.png);
}

.icon-missing_photoshoot.variation-black {
  background-image: url(1x/icon-missing_photoshoot-black-lg.png);
}

.icon-missing_photoshoot.variation-white {
  background-image: url(1x/icon-missing_photoshoot-white-sm.png);
}

.icon-missing_photoshoot.variation-white {
  background-image: url(1x/icon-missing_photoshoot-white-md.png);
}

.icon-missing_photoshoot.variation-white {
  background-image: url(1x/icon-missing_photoshoot-white-lg.png);
}

.icon-missing_photoshoot.variation-success {
  background-image: url(1x/icon-missing_photoshoot-success-sm.png);
}

.icon-missing_photoshoot.variation-success {
  background-image: url(1x/icon-missing_photoshoot-success-md.png);
}

.icon-missing_photoshoot.variation-success {
  background-image: url(1x/icon-missing_photoshoot-success-lg.png);
}

.icon-missing_photoshoot.variation-error {
  background-image: url(1x/icon-missing_photoshoot-error-sm.png);
}

.icon-missing_photoshoot.variation-error {
  background-image: url(1x/icon-missing_photoshoot-error-md.png);
}

.icon-missing_photoshoot.variation-error {
  background-image: url(1x/icon-missing_photoshoot-error-lg.png);
}

.icon-missing_photoshoot.variation-warning {
  background-image: url(1x/icon-missing_photoshoot-warning-sm.png);
}

.icon-missing_photoshoot.variation-warning {
  background-image: url(1x/icon-missing_photoshoot-warning-md.png);
}

.icon-missing_photoshoot.variation-warning {
  background-image: url(1x/icon-missing_photoshoot-warning-lg.png);
}

.icon-moodboard.variation-primary {
  background-image: url(1x/icon-moodboard-primary-sm.png);
}

.icon-moodboard.size-sm {
  width: 18px;
  height: 18px;
}

.icon-moodboard.variation-primary {
  background-image: url(1x/icon-moodboard-primary-md.png);
}

.icon-moodboard.size-md {
  width: 21px;
  height: 21px;
}

.icon-moodboard.variation-primary {
  background-image: url(1x/icon-moodboard-primary-lg.png);
}

.icon-moodboard.size-lg {
  width: 29px;
  height: 29px;
}

.icon-moodboard.variation-secondary {
  background-image: url(1x/icon-moodboard-secondary-sm.png);
}

.icon-moodboard.variation-secondary {
  background-image: url(1x/icon-moodboard-secondary-md.png);
}

.icon-moodboard.variation-secondary {
  background-image: url(1x/icon-moodboard-secondary-lg.png);
}

.icon-moodboard.variation-black {
  background-image: url(1x/icon-moodboard-black-sm.png);
}

.icon-moodboard.variation-black {
  background-image: url(1x/icon-moodboard-black-md.png);
}

.icon-moodboard.variation-black {
  background-image: url(1x/icon-moodboard-black-lg.png);
}

.icon-moodboard.variation-white {
  background-image: url(1x/icon-moodboard-white-sm.png);
}

.icon-moodboard.variation-white {
  background-image: url(1x/icon-moodboard-white-md.png);
}

.icon-moodboard.variation-white {
  background-image: url(1x/icon-moodboard-white-lg.png);
}

.icon-moodboard.variation-success {
  background-image: url(1x/icon-moodboard-success-sm.png);
}

.icon-moodboard.variation-success {
  background-image: url(1x/icon-moodboard-success-md.png);
}

.icon-moodboard.variation-success {
  background-image: url(1x/icon-moodboard-success-lg.png);
}

.icon-moodboard.variation-error {
  background-image: url(1x/icon-moodboard-error-sm.png);
}

.icon-moodboard.variation-error {
  background-image: url(1x/icon-moodboard-error-md.png);
}

.icon-moodboard.variation-error {
  background-image: url(1x/icon-moodboard-error-lg.png);
}

.icon-moodboard.variation-warning {
  background-image: url(1x/icon-moodboard-warning-sm.png);
}

.icon-moodboard.variation-warning {
  background-image: url(1x/icon-moodboard-warning-md.png);
}

.icon-moodboard.variation-warning {
  background-image: url(1x/icon-moodboard-warning-lg.png);
}

.icon-no.variation-primary {
  background-image: url(1x/icon-no-primary-sm.png);
}

.icon-no.size-sm {
  width: 18px;
  height: 18px;
}

.icon-no.variation-primary {
  background-image: url(1x/icon-no-primary-md.png);
}

.icon-no.size-md {
  width: 21px;
  height: 21px;
}

.icon-no.variation-primary {
  background-image: url(1x/icon-no-primary-lg.png);
}

.icon-no.size-lg {
  width: 29px;
  height: 29px;
}

.icon-no.variation-secondary {
  background-image: url(1x/icon-no-secondary-sm.png);
}

.icon-no.variation-secondary {
  background-image: url(1x/icon-no-secondary-md.png);
}

.icon-no.variation-secondary {
  background-image: url(1x/icon-no-secondary-lg.png);
}

.icon-no.variation-black {
  background-image: url(1x/icon-no-black-sm.png);
}

.icon-no.variation-black {
  background-image: url(1x/icon-no-black-md.png);
}

.icon-no.variation-black {
  background-image: url(1x/icon-no-black-lg.png);
}

.icon-no.variation-white {
  background-image: url(1x/icon-no-white-sm.png);
}

.icon-no.variation-white {
  background-image: url(1x/icon-no-white-md.png);
}

.icon-no.variation-white {
  background-image: url(1x/icon-no-white-lg.png);
}

.icon-no.variation-success {
  background-image: url(1x/icon-no-success-sm.png);
}

.icon-no.variation-success {
  background-image: url(1x/icon-no-success-md.png);
}

.icon-no.variation-success {
  background-image: url(1x/icon-no-success-lg.png);
}

.icon-no.variation-error {
  background-image: url(1x/icon-no-error-sm.png);
}

.icon-no.variation-error {
  background-image: url(1x/icon-no-error-md.png);
}

.icon-no.variation-error {
  background-image: url(1x/icon-no-error-lg.png);
}

.icon-no.variation-warning {
  background-image: url(1x/icon-no-warning-sm.png);
}

.icon-no.variation-warning {
  background-image: url(1x/icon-no-warning-md.png);
}

.icon-no.variation-warning {
  background-image: url(1x/icon-no-warning-lg.png);
}

.icon-parking.variation-primary {
  background-image: url(1x/icon-parking-primary-sm.png);
}

.icon-parking.size-sm {
  width: 18px;
  height: 18px;
}

.icon-parking.variation-primary {
  background-image: url(1x/icon-parking-primary-md.png);
}

.icon-parking.size-md {
  width: 21px;
  height: 21px;
}

.icon-parking.variation-primary {
  background-image: url(1x/icon-parking-primary-lg.png);
}

.icon-parking.size-lg {
  width: 29px;
  height: 29px;
}

.icon-parking.variation-secondary {
  background-image: url(1x/icon-parking-secondary-sm.png);
}

.icon-parking.variation-secondary {
  background-image: url(1x/icon-parking-secondary-md.png);
}

.icon-parking.variation-secondary {
  background-image: url(1x/icon-parking-secondary-lg.png);
}

.icon-parking.variation-black {
  background-image: url(1x/icon-parking-black-sm.png);
}

.icon-parking.variation-black {
  background-image: url(1x/icon-parking-black-md.png);
}

.icon-parking.variation-black {
  background-image: url(1x/icon-parking-black-lg.png);
}

.icon-parking.variation-white {
  background-image: url(1x/icon-parking-white-sm.png);
}

.icon-parking.variation-white {
  background-image: url(1x/icon-parking-white-md.png);
}

.icon-parking.variation-white {
  background-image: url(1x/icon-parking-white-lg.png);
}

.icon-parking.variation-success {
  background-image: url(1x/icon-parking-success-sm.png);
}

.icon-parking.variation-success {
  background-image: url(1x/icon-parking-success-md.png);
}

.icon-parking.variation-success {
  background-image: url(1x/icon-parking-success-lg.png);
}

.icon-parking.variation-error {
  background-image: url(1x/icon-parking-error-sm.png);
}

.icon-parking.variation-error {
  background-image: url(1x/icon-parking-error-md.png);
}

.icon-parking.variation-error {
  background-image: url(1x/icon-parking-error-lg.png);
}

.icon-parking.variation-warning {
  background-image: url(1x/icon-parking-warning-sm.png);
}

.icon-parking.variation-warning {
  background-image: url(1x/icon-parking-warning-md.png);
}

.icon-parking.variation-warning {
  background-image: url(1x/icon-parking-warning-lg.png);
}

.icon-person.variation-primary {
  background-image: url(1x/icon-person-primary-sm.png);
}

.icon-person.size-sm {
  width: 18px;
  height: 18px;
}

.icon-person.variation-primary {
  background-image: url(1x/icon-person-primary-md.png);
}

.icon-person.size-md {
  width: 21px;
  height: 21px;
}

.icon-person.variation-primary {
  background-image: url(1x/icon-person-primary-lg.png);
}

.icon-person.size-lg {
  width: 29px;
  height: 29px;
}

.icon-person.variation-secondary {
  background-image: url(1x/icon-person-secondary-sm.png);
}

.icon-person.variation-secondary {
  background-image: url(1x/icon-person-secondary-md.png);
}

.icon-person.variation-secondary {
  background-image: url(1x/icon-person-secondary-lg.png);
}

.icon-person.variation-black {
  background-image: url(1x/icon-person-black-sm.png);
}

.icon-person.variation-black {
  background-image: url(1x/icon-person-black-md.png);
}

.icon-person.variation-black {
  background-image: url(1x/icon-person-black-lg.png);
}

.icon-person.variation-white {
  background-image: url(1x/icon-person-white-sm.png);
}

.icon-person.variation-white {
  background-image: url(1x/icon-person-white-md.png);
}

.icon-person.variation-white {
  background-image: url(1x/icon-person-white-lg.png);
}

.icon-person.variation-success {
  background-image: url(1x/icon-person-success-sm.png);
}

.icon-person.variation-success {
  background-image: url(1x/icon-person-success-md.png);
}

.icon-person.variation-success {
  background-image: url(1x/icon-person-success-lg.png);
}

.icon-person.variation-error {
  background-image: url(1x/icon-person-error-sm.png);
}

.icon-person.variation-error {
  background-image: url(1x/icon-person-error-md.png);
}

.icon-person.variation-error {
  background-image: url(1x/icon-person-error-lg.png);
}

.icon-person.variation-warning {
  background-image: url(1x/icon-person-warning-sm.png);
}

.icon-person.variation-warning {
  background-image: url(1x/icon-person-warning-md.png);
}

.icon-person.variation-warning {
  background-image: url(1x/icon-person-warning-lg.png);
}

.icon-photoshoot_id.variation-primary {
  background-image: url(1x/icon-photoshoot_id-primary-sm.png);
}

.icon-photoshoot_id.size-sm {
  width: 18px;
  height: 18px;
}

.icon-photoshoot_id.variation-primary {
  background-image: url(1x/icon-photoshoot_id-primary-md.png);
}

.icon-photoshoot_id.size-md {
  width: 21px;
  height: 21px;
}

.icon-photoshoot_id.variation-primary {
  background-image: url(1x/icon-photoshoot_id-primary-lg.png);
}

.icon-photoshoot_id.size-lg {
  width: 29px;
  height: 29px;
}

.icon-photoshoot_id.variation-secondary {
  background-image: url(1x/icon-photoshoot_id-secondary-sm.png);
}

.icon-photoshoot_id.variation-secondary {
  background-image: url(1x/icon-photoshoot_id-secondary-md.png);
}

.icon-photoshoot_id.variation-secondary {
  background-image: url(1x/icon-photoshoot_id-secondary-lg.png);
}

.icon-photoshoot_id.variation-black {
  background-image: url(1x/icon-photoshoot_id-black-sm.png);
}

.icon-photoshoot_id.variation-black {
  background-image: url(1x/icon-photoshoot_id-black-md.png);
}

.icon-photoshoot_id.variation-black {
  background-image: url(1x/icon-photoshoot_id-black-lg.png);
}

.icon-photoshoot_id.variation-white {
  background-image: url(1x/icon-photoshoot_id-white-sm.png);
}

.icon-photoshoot_id.variation-white {
  background-image: url(1x/icon-photoshoot_id-white-md.png);
}

.icon-photoshoot_id.variation-white {
  background-image: url(1x/icon-photoshoot_id-white-lg.png);
}

.icon-photoshoot_id.variation-success {
  background-image: url(1x/icon-photoshoot_id-success-sm.png);
}

.icon-photoshoot_id.variation-success {
  background-image: url(1x/icon-photoshoot_id-success-md.png);
}

.icon-photoshoot_id.variation-success {
  background-image: url(1x/icon-photoshoot_id-success-lg.png);
}

.icon-photoshoot_id.variation-error {
  background-image: url(1x/icon-photoshoot_id-error-sm.png);
}

.icon-photoshoot_id.variation-error {
  background-image: url(1x/icon-photoshoot_id-error-md.png);
}

.icon-photoshoot_id.variation-error {
  background-image: url(1x/icon-photoshoot_id-error-lg.png);
}

.icon-photoshoot_id.variation-warning {
  background-image: url(1x/icon-photoshoot_id-warning-sm.png);
}

.icon-photoshoot_id.variation-warning {
  background-image: url(1x/icon-photoshoot_id-warning-md.png);
}

.icon-photoshoot_id.variation-warning {
  background-image: url(1x/icon-photoshoot_id-warning-lg.png);
}

.icon-produce.variation-primary {
  background-image: url(1x/icon-produce-primary-sm.png);
}

.icon-produce.size-sm {
  width: 18px;
  height: 18px;
}

.icon-produce.variation-primary {
  background-image: url(1x/icon-produce-primary-md.png);
}

.icon-produce.size-md {
  width: 21px;
  height: 21px;
}

.icon-produce.variation-primary {
  background-image: url(1x/icon-produce-primary-lg.png);
}

.icon-produce.size-lg {
  width: 29px;
  height: 29px;
}

.icon-produce.variation-secondary {
  background-image: url(1x/icon-produce-secondary-sm.png);
}

.icon-produce.variation-secondary {
  background-image: url(1x/icon-produce-secondary-md.png);
}

.icon-produce.variation-secondary {
  background-image: url(1x/icon-produce-secondary-lg.png);
}

.icon-produce.variation-black {
  background-image: url(1x/icon-produce-black-sm.png);
}

.icon-produce.variation-black {
  background-image: url(1x/icon-produce-black-md.png);
}

.icon-produce.variation-black {
  background-image: url(1x/icon-produce-black-lg.png);
}

.icon-produce.variation-white {
  background-image: url(1x/icon-produce-white-sm.png);
}

.icon-produce.variation-white {
  background-image: url(1x/icon-produce-white-md.png);
}

.icon-produce.variation-white {
  background-image: url(1x/icon-produce-white-lg.png);
}

.icon-produce.variation-success {
  background-image: url(1x/icon-produce-success-sm.png);
}

.icon-produce.variation-success {
  background-image: url(1x/icon-produce-success-md.png);
}

.icon-produce.variation-success {
  background-image: url(1x/icon-produce-success-lg.png);
}

.icon-produce.variation-error {
  background-image: url(1x/icon-produce-error-sm.png);
}

.icon-produce.variation-error {
  background-image: url(1x/icon-produce-error-md.png);
}

.icon-produce.variation-error {
  background-image: url(1x/icon-produce-error-lg.png);
}

.icon-produce.variation-warning {
  background-image: url(1x/icon-produce-warning-sm.png);
}

.icon-produce.variation-warning {
  background-image: url(1x/icon-produce-warning-md.png);
}

.icon-produce.variation-warning {
  background-image: url(1x/icon-produce-warning-lg.png);
}

.icon-product.variation-primary {
  background-image: url(1x/icon-product-primary-sm.png);
}

.icon-product.size-sm {
  width: 18px;
  height: 18px;
}

.icon-product.variation-primary {
  background-image: url(1x/icon-product-primary-md.png);
}

.icon-product.size-md {
  width: 21px;
  height: 21px;
}

.icon-product.variation-primary {
  background-image: url(1x/icon-product-primary-lg.png);
}

.icon-product.size-lg {
  width: 29px;
  height: 29px;
}

.icon-product.variation-secondary {
  background-image: url(1x/icon-product-secondary-sm.png);
}

.icon-product.variation-secondary {
  background-image: url(1x/icon-product-secondary-md.png);
}

.icon-product.variation-secondary {
  background-image: url(1x/icon-product-secondary-lg.png);
}

.icon-product.variation-black {
  background-image: url(1x/icon-product-black-sm.png);
}

.icon-product.variation-black {
  background-image: url(1x/icon-product-black-md.png);
}

.icon-product.variation-black {
  background-image: url(1x/icon-product-black-lg.png);
}

.icon-product.variation-white {
  background-image: url(1x/icon-product-white-sm.png);
}

.icon-product.variation-white {
  background-image: url(1x/icon-product-white-md.png);
}

.icon-product.variation-white {
  background-image: url(1x/icon-product-white-lg.png);
}

.icon-product.variation-success {
  background-image: url(1x/icon-product-success-sm.png);
}

.icon-product.variation-success {
  background-image: url(1x/icon-product-success-md.png);
}

.icon-product.variation-success {
  background-image: url(1x/icon-product-success-lg.png);
}

.icon-product.variation-error {
  background-image: url(1x/icon-product-error-sm.png);
}

.icon-product.variation-error {
  background-image: url(1x/icon-product-error-md.png);
}

.icon-product.variation-error {
  background-image: url(1x/icon-product-error-lg.png);
}

.icon-product.variation-warning {
  background-image: url(1x/icon-product-warning-sm.png);
}

.icon-product.variation-warning {
  background-image: url(1x/icon-product-warning-md.png);
}

.icon-product.variation-warning {
  background-image: url(1x/icon-product-warning-lg.png);
}

.icon-rename.variation-primary {
  background-image: url(1x/icon-rename-primary-sm.png);
}

.icon-rename.size-sm {
  width: 18px;
  height: 18px;
}

.icon-rename.variation-primary {
  background-image: url(1x/icon-rename-primary-md.png);
}

.icon-rename.size-md {
  width: 21px;
  height: 21px;
}

.icon-rename.variation-primary {
  background-image: url(1x/icon-rename-primary-lg.png);
}

.icon-rename.size-lg {
  width: 29px;
  height: 29px;
}

.icon-rename.variation-secondary {
  background-image: url(1x/icon-rename-secondary-sm.png);
}

.icon-rename.variation-secondary {
  background-image: url(1x/icon-rename-secondary-md.png);
}

.icon-rename.variation-secondary {
  background-image: url(1x/icon-rename-secondary-lg.png);
}

.icon-rename.variation-black {
  background-image: url(1x/icon-rename-black-sm.png);
}

.icon-rename.variation-black {
  background-image: url(1x/icon-rename-black-md.png);
}

.icon-rename.variation-black {
  background-image: url(1x/icon-rename-black-lg.png);
}

.icon-rename.variation-white {
  background-image: url(1x/icon-rename-white-sm.png);
}

.icon-rename.variation-white {
  background-image: url(1x/icon-rename-white-md.png);
}

.icon-rename.variation-white {
  background-image: url(1x/icon-rename-white-lg.png);
}

.icon-rename.variation-success {
  background-image: url(1x/icon-rename-success-sm.png);
}

.icon-rename.variation-success {
  background-image: url(1x/icon-rename-success-md.png);
}

.icon-rename.variation-success {
  background-image: url(1x/icon-rename-success-lg.png);
}

.icon-rename.variation-error {
  background-image: url(1x/icon-rename-error-sm.png);
}

.icon-rename.variation-error {
  background-image: url(1x/icon-rename-error-md.png);
}

.icon-rename.variation-error {
  background-image: url(1x/icon-rename-error-lg.png);
}

.icon-rename.variation-warning {
  background-image: url(1x/icon-rename-warning-sm.png);
}

.icon-rename.variation-warning {
  background-image: url(1x/icon-rename-warning-md.png);
}

.icon-rename.variation-warning {
  background-image: url(1x/icon-rename-warning-lg.png);
}

.icon-search_result.variation-primary {
  background-image: url(1x/icon-search_result-primary-sm.png);
}

.icon-search_result.size-sm {
  width: 18px;
  height: 18px;
}

.icon-search_result.variation-primary {
  background-image: url(1x/icon-search_result-primary-md.png);
}

.icon-search_result.size-md {
  width: 21px;
  height: 21px;
}

.icon-search_result.variation-primary {
  background-image: url(1x/icon-search_result-primary-lg.png);
}

.icon-search_result.size-lg {
  width: 29px;
  height: 29px;
}

.icon-search_result.variation-secondary {
  background-image: url(1x/icon-search_result-secondary-sm.png);
}

.icon-search_result.variation-secondary {
  background-image: url(1x/icon-search_result-secondary-md.png);
}

.icon-search_result.variation-secondary {
  background-image: url(1x/icon-search_result-secondary-lg.png);
}

.icon-search_result.variation-black {
  background-image: url(1x/icon-search_result-black-sm.png);
}

.icon-search_result.variation-black {
  background-image: url(1x/icon-search_result-black-md.png);
}

.icon-search_result.variation-black {
  background-image: url(1x/icon-search_result-black-lg.png);
}

.icon-search_result.variation-white {
  background-image: url(1x/icon-search_result-white-sm.png);
}

.icon-search_result.variation-white {
  background-image: url(1x/icon-search_result-white-md.png);
}

.icon-search_result.variation-white {
  background-image: url(1x/icon-search_result-white-lg.png);
}

.icon-search_result.variation-success {
  background-image: url(1x/icon-search_result-success-sm.png);
}

.icon-search_result.variation-success {
  background-image: url(1x/icon-search_result-success-md.png);
}

.icon-search_result.variation-success {
  background-image: url(1x/icon-search_result-success-lg.png);
}

.icon-search_result.variation-error {
  background-image: url(1x/icon-search_result-error-sm.png);
}

.icon-search_result.variation-error {
  background-image: url(1x/icon-search_result-error-md.png);
}

.icon-search_result.variation-error {
  background-image: url(1x/icon-search_result-error-lg.png);
}

.icon-search_result.variation-warning {
  background-image: url(1x/icon-search_result-warning-sm.png);
}

.icon-search_result.variation-warning {
  background-image: url(1x/icon-search_result-warning-md.png);
}

.icon-search_result.variation-warning {
  background-image: url(1x/icon-search_result-warning-lg.png);
}

.icon-search.variation-primary {
  background-image: url(1x/icon-search-primary-sm.png);
}

.icon-search.size-sm {
  width: 18px;
  height: 18px;
}

.icon-search.variation-primary {
  background-image: url(1x/icon-search-primary-md.png);
}

.icon-search.size-md {
  width: 21px;
  height: 21px;
}

.icon-search.variation-primary {
  background-image: url(1x/icon-search-primary-lg.png);
}

.icon-search.size-lg {
  width: 29px;
  height: 29px;
}

.icon-search.variation-secondary {
  background-image: url(1x/icon-search-secondary-sm.png);
}

.icon-search.variation-secondary {
  background-image: url(1x/icon-search-secondary-md.png);
}

.icon-search.variation-secondary {
  background-image: url(1x/icon-search-secondary-lg.png);
}

.icon-search.variation-black {
  background-image: url(1x/icon-search-black-sm.png);
}

.icon-search.variation-black {
  background-image: url(1x/icon-search-black-md.png);
}

.icon-search.variation-black {
  background-image: url(1x/icon-search-black-lg.png);
}

.icon-search.variation-white {
  background-image: url(1x/icon-search-white-sm.png);
}

.icon-search.variation-white {
  background-image: url(1x/icon-search-white-md.png);
}

.icon-search.variation-white {
  background-image: url(1x/icon-search-white-lg.png);
}

.icon-search.variation-success {
  background-image: url(1x/icon-search-success-sm.png);
}

.icon-search.variation-success {
  background-image: url(1x/icon-search-success-md.png);
}

.icon-search.variation-success {
  background-image: url(1x/icon-search-success-lg.png);
}

.icon-search.variation-error {
  background-image: url(1x/icon-search-error-sm.png);
}

.icon-search.variation-error {
  background-image: url(1x/icon-search-error-md.png);
}

.icon-search.variation-error {
  background-image: url(1x/icon-search-error-lg.png);
}

.icon-search.variation-warning {
  background-image: url(1x/icon-search-warning-sm.png);
}

.icon-search.variation-warning {
  background-image: url(1x/icon-search-warning-md.png);
}

.icon-search.variation-warning {
  background-image: url(1x/icon-search-warning-lg.png);
}

.icon-submission_complete.variation-primary {
  background-image: url(1x/icon-submission_complete-primary-sm.png);
}

.icon-submission_complete.size-sm {
  width: 18px;
  height: 18px;
}

.icon-submission_complete.variation-primary {
  background-image: url(1x/icon-submission_complete-primary-md.png);
}

.icon-submission_complete.size-md {
  width: 21px;
  height: 21px;
}

.icon-submission_complete.variation-primary {
  background-image: url(1x/icon-submission_complete-primary-lg.png);
}

.icon-submission_complete.size-lg {
  width: 29px;
  height: 29px;
}

.icon-submission_complete.variation-secondary {
  background-image: url(1x/icon-submission_complete-secondary-sm.png);
}

.icon-submission_complete.variation-secondary {
  background-image: url(1x/icon-submission_complete-secondary-md.png);
}

.icon-submission_complete.variation-secondary {
  background-image: url(1x/icon-submission_complete-secondary-lg.png);
}

.icon-submission_complete.variation-black {
  background-image: url(1x/icon-submission_complete-black-sm.png);
}

.icon-submission_complete.variation-black {
  background-image: url(1x/icon-submission_complete-black-md.png);
}

.icon-submission_complete.variation-black {
  background-image: url(1x/icon-submission_complete-black-lg.png);
}

.icon-submission_complete.variation-white {
  background-image: url(1x/icon-submission_complete-white-sm.png);
}

.icon-submission_complete.variation-white {
  background-image: url(1x/icon-submission_complete-white-md.png);
}

.icon-submission_complete.variation-white {
  background-image: url(1x/icon-submission_complete-white-lg.png);
}

.icon-submission_complete.variation-success {
  background-image: url(1x/icon-submission_complete-success-sm.png);
}

.icon-submission_complete.variation-success {
  background-image: url(1x/icon-submission_complete-success-md.png);
}

.icon-submission_complete.variation-success {
  background-image: url(1x/icon-submission_complete-success-lg.png);
}

.icon-submission_complete.variation-error {
  background-image: url(1x/icon-submission_complete-error-sm.png);
}

.icon-submission_complete.variation-error {
  background-image: url(1x/icon-submission_complete-error-md.png);
}

.icon-submission_complete.variation-error {
  background-image: url(1x/icon-submission_complete-error-lg.png);
}

.icon-submission_complete.variation-warning {
  background-image: url(1x/icon-submission_complete-warning-sm.png);
}

.icon-submission_complete.variation-warning {
  background-image: url(1x/icon-submission_complete-warning-md.png);
}

.icon-submission_complete.variation-warning {
  background-image: url(1x/icon-submission_complete-warning-lg.png);
}

.icon-submission.variation-primary {
  background-image: url(1x/icon-submission-primary-sm.png);
}

.icon-submission.size-sm {
  width: 18px;
  height: 18px;
}

.icon-submission.variation-primary {
  background-image: url(1x/icon-submission-primary-md.png);
}

.icon-submission.size-md {
  width: 21px;
  height: 21px;
}

.icon-submission.variation-primary {
  background-image: url(1x/icon-submission-primary-lg.png);
}

.icon-submission.size-lg {
  width: 29px;
  height: 29px;
}

.icon-submission.variation-secondary {
  background-image: url(1x/icon-submission-secondary-sm.png);
}

.icon-submission.variation-secondary {
  background-image: url(1x/icon-submission-secondary-md.png);
}

.icon-submission.variation-secondary {
  background-image: url(1x/icon-submission-secondary-lg.png);
}

.icon-submission.variation-black {
  background-image: url(1x/icon-submission-black-sm.png);
}

.icon-submission.variation-black {
  background-image: url(1x/icon-submission-black-md.png);
}

.icon-submission.variation-black {
  background-image: url(1x/icon-submission-black-lg.png);
}

.icon-submission.variation-white {
  background-image: url(1x/icon-submission-white-sm.png);
}

.icon-submission.variation-white {
  background-image: url(1x/icon-submission-white-md.png);
}

.icon-submission.variation-white {
  background-image: url(1x/icon-submission-white-lg.png);
}

.icon-submission.variation-success {
  background-image: url(1x/icon-submission-success-sm.png);
}

.icon-submission.variation-success {
  background-image: url(1x/icon-submission-success-md.png);
}

.icon-submission.variation-success {
  background-image: url(1x/icon-submission-success-lg.png);
}

.icon-submission.variation-error {
  background-image: url(1x/icon-submission-error-sm.png);
}

.icon-submission.variation-error {
  background-image: url(1x/icon-submission-error-md.png);
}

.icon-submission.variation-error {
  background-image: url(1x/icon-submission-error-lg.png);
}

.icon-submission.variation-warning {
  background-image: url(1x/icon-submission-warning-sm.png);
}

.icon-submission.variation-warning {
  background-image: url(1x/icon-submission-warning-md.png);
}

.icon-submission.variation-warning {
  background-image: url(1x/icon-submission-warning-lg.png);
}

.icon-time.variation-primary {
  background-image: url(1x/icon-time-primary-sm.png);
}

.icon-time.size-sm {
  width: 18px;
  height: 18px;
}

.icon-time.variation-primary {
  background-image: url(1x/icon-time-primary-md.png);
}

.icon-time.size-md {
  width: 21px;
  height: 21px;
}

.icon-time.variation-primary {
  background-image: url(1x/icon-time-primary-lg.png);
}

.icon-time.size-lg {
  width: 29px;
  height: 29px;
}

.icon-time.variation-secondary {
  background-image: url(1x/icon-time-secondary-sm.png);
}

.icon-time.variation-secondary {
  background-image: url(1x/icon-time-secondary-md.png);
}

.icon-time.variation-secondary {
  background-image: url(1x/icon-time-secondary-lg.png);
}

.icon-time.variation-black {
  background-image: url(1x/icon-time-black-sm.png);
}

.icon-time.variation-black {
  background-image: url(1x/icon-time-black-md.png);
}

.icon-time.variation-black {
  background-image: url(1x/icon-time-black-lg.png);
}

.icon-time.variation-white {
  background-image: url(1x/icon-time-white-sm.png);
}

.icon-time.variation-white {
  background-image: url(1x/icon-time-white-md.png);
}

.icon-time.variation-white {
  background-image: url(1x/icon-time-white-lg.png);
}

.icon-time.variation-success {
  background-image: url(1x/icon-time-success-sm.png);
}

.icon-time.variation-success {
  background-image: url(1x/icon-time-success-md.png);
}

.icon-time.variation-success {
  background-image: url(1x/icon-time-success-lg.png);
}

.icon-time.variation-error {
  background-image: url(1x/icon-time-error-sm.png);
}

.icon-time.variation-error {
  background-image: url(1x/icon-time-error-md.png);
}

.icon-time.variation-error {
  background-image: url(1x/icon-time-error-lg.png);
}

.icon-time.variation-warning {
  background-image: url(1x/icon-time-warning-sm.png);
}

.icon-time.variation-warning {
  background-image: url(1x/icon-time-warning-md.png);
}

.icon-time.variation-warning {
  background-image: url(1x/icon-time-warning-lg.png);
}

.icon-upload.variation-primary {
  background-image: url(1x/icon-upload-primary-sm.png);
}

.icon-upload.size-sm {
  width: 18px;
  height: 18px;
}

.icon-upload.variation-primary {
  background-image: url(1x/icon-upload-primary-md.png);
}

.icon-upload.size-md {
  width: 21px;
  height: 21px;
}

.icon-upload.variation-primary {
  background-image: url(1x/icon-upload-primary-lg.png);
}

.icon-upload.size-lg {
  width: 29px;
  height: 29px;
}

.icon-upload.variation-secondary {
  background-image: url(1x/icon-upload-secondary-sm.png);
}

.icon-upload.variation-secondary {
  background-image: url(1x/icon-upload-secondary-md.png);
}

.icon-upload.variation-secondary {
  background-image: url(1x/icon-upload-secondary-lg.png);
}

.icon-upload.variation-black {
  background-image: url(1x/icon-upload-black-sm.png);
}

.icon-upload.variation-black {
  background-image: url(1x/icon-upload-black-md.png);
}

.icon-upload.variation-black {
  background-image: url(1x/icon-upload-black-lg.png);
}

.icon-upload.variation-white {
  background-image: url(1x/icon-upload-white-sm.png);
}

.icon-upload.variation-white {
  background-image: url(1x/icon-upload-white-md.png);
}

.icon-upload.variation-white {
  background-image: url(1x/icon-upload-white-lg.png);
}

.icon-upload.variation-success {
  background-image: url(1x/icon-upload-success-sm.png);
}

.icon-upload.variation-success {
  background-image: url(1x/icon-upload-success-md.png);
}

.icon-upload.variation-success {
  background-image: url(1x/icon-upload-success-lg.png);
}

.icon-upload.variation-error {
  background-image: url(1x/icon-upload-error-sm.png);
}

.icon-upload.variation-error {
  background-image: url(1x/icon-upload-error-md.png);
}

.icon-upload.variation-error {
  background-image: url(1x/icon-upload-error-lg.png);
}

.icon-upload.variation-warning {
  background-image: url(1x/icon-upload-warning-sm.png);
}

.icon-upload.variation-warning {
  background-image: url(1x/icon-upload-warning-md.png);
}

.icon-upload.variation-warning {
  background-image: url(1x/icon-upload-warning-lg.png);
}

.icon-weather.variation-primary {
  background-image: url(1x/icon-weather-primary-sm.png);
}

.icon-weather.size-sm {
  width: 18px;
  height: 18px;
}

.icon-weather.variation-primary {
  background-image: url(1x/icon-weather-primary-md.png);
}

.icon-weather.size-md {
  width: 21px;
  height: 21px;
}

.icon-weather.variation-primary {
  background-image: url(1x/icon-weather-primary-lg.png);
}

.icon-weather.size-lg {
  width: 29px;
  height: 29px;
}

.icon-weather.variation-secondary {
  background-image: url(1x/icon-weather-secondary-sm.png);
}

.icon-weather.variation-secondary {
  background-image: url(1x/icon-weather-secondary-md.png);
}

.icon-weather.variation-secondary {
  background-image: url(1x/icon-weather-secondary-lg.png);
}

.icon-weather.variation-black {
  background-image: url(1x/icon-weather-black-sm.png);
}

.icon-weather.variation-black {
  background-image: url(1x/icon-weather-black-md.png);
}

.icon-weather.variation-black {
  background-image: url(1x/icon-weather-black-lg.png);
}

.icon-weather.variation-white {
  background-image: url(1x/icon-weather-white-sm.png);
}

.icon-weather.variation-white {
  background-image: url(1x/icon-weather-white-md.png);
}

.icon-weather.variation-white {
  background-image: url(1x/icon-weather-white-lg.png);
}

.icon-weather.variation-success {
  background-image: url(1x/icon-weather-success-sm.png);
}

.icon-weather.variation-success {
  background-image: url(1x/icon-weather-success-md.png);
}

.icon-weather.variation-success {
  background-image: url(1x/icon-weather-success-lg.png);
}

.icon-weather.variation-error {
  background-image: url(1x/icon-weather-error-sm.png);
}

.icon-weather.variation-error {
  background-image: url(1x/icon-weather-error-md.png);
}

.icon-weather.variation-error {
  background-image: url(1x/icon-weather-error-lg.png);
}

.icon-weather.variation-warning {
  background-image: url(1x/icon-weather-warning-sm.png);
}

.icon-weather.variation-warning {
  background-image: url(1x/icon-weather-warning-md.png);
}

.icon-weather.variation-warning {
  background-image: url(1x/icon-weather-warning-lg.png);
}

@media only screen {
  .variation-primary {
    filter: saturate(0);
  }

  .variation-secondary {
    filter: invert(64%) sepia(43%) saturate(0%) hue-rotate(135deg)
      brightness(89%) contrast(82%);
  }

  .variation-black {
    filter: saturate(0);
  }

  .variation-white {
    filter: saturate(0) brightness(0) invert(1);
  }

  .variation-success {
    filter: invert(21%) sepia(99%) saturate(345%) hue-rotate(99deg)
      brightness(96%) contrast(96%);
  }

  .variation-error {
    filter: invert(14%) sepia(54%) saturate(2994%) hue-rotate(335deg)
      brightness(99%) contrast(93%);
  }

  .variation-warning {
    filter: invert(29%) sepia(55%) saturate(846%) hue-rotate(11deg)
      brightness(92%) contrast(102%);
  }

  .icon-accepted {
    background-image: url(../../svg/accepted.svg) !important;
  }

  .icon-add_comment {
    background-image: url(../../svg/add_comment.svg) !important;
  }

  .icon-add_shoot {
    background-image: url(../../svg/add_shoot.svg) !important;
  }

  .icon-attention {
    background-image: url(../../svg/attention.svg) !important;
  }

  .icon-back {
    background-image: url(../../svg/back.svg) !important;
  }

  .icon-brief {
    background-image: url(../../svg/brief.svg) !important;
  }

  .icon-calendar {
    background-image: url(../../svg/calendar.svg) !important;
  }

  .icon-camera {
    background-image: url(../../svg/camera.svg) !important;
  }

  .icon-check {
    background-image: url(../../svg/check.svg) !important;
  }

  .icon-checklist {
    background-image: url(../../svg/checklist.svg) !important;
  }

  .icon-comment {
    background-image: url(../../svg/comment.svg) !important;
  }

  .icon-download_csv {
    background-image: url(../../svg/download_csv.svg) !important;
  }

  .icon-download {
    background-image: url(../../svg/download.svg) !important;
  }

  .icon-earning {
    background-image: url(../../svg/earning.svg) !important;
  }

  .icon-edit_date {
    background-image: url(../../svg/edit_date.svg) !important;
  }

  .icon-edit {
    background-image: url(../../svg/edit.svg) !important;
  }

  .icon-equipment {
    background-image: url(../../svg/equipment.svg) !important;
  }

  .icon-error {
    background-image: url(../../svg/error.svg) !important;
  }

  .icon-growth {
    background-image: url(../../svg/growth.svg) !important;
  }

  .icon-guidelines {
    background-image: url(../../svg/guidelines.svg) !important;
  }

  .icon-house {
    background-image: url(../../svg/house.svg) !important;
  }

  .icon-id {
    background-image: url(../../svg/id.svg) !important;
  }

  .icon-idea {
    background-image: url(../../svg/idea.svg) !important;
  }

  .icon-impact {
    background-image: url(../../svg/impact.svg) !important;
  }

  .icon-lemon {
    background-image: url(../../svg/lemon.svg) !important;
  }

  .icon-location {
    background-image: url(../../svg/location.svg) !important;
  }

  .icon-log_out {
    background-image: url(../../svg/log_out.svg) !important;
  }

  .icon-message {
    background-image: url(../../svg/message.svg) !important;
  }

  .icon-missing_photoshoot {
    background-image: url(../../svg/missing_photoshoot.svg) !important;
  }

  .icon-moodboard {
    background-image: url(../../svg/moodboard.svg) !important;
  }

  .icon-no {
    background-image: url(../../svg/no.svg) !important;
  }

  .icon-parking {
    background-image: url(../../svg/parking.svg) !important;
  }

  .icon-person {
    background-image: url(../../svg/person.svg) !important;
  }

  .icon-photoshoot_id {
    background-image: url(../../svg/photoshoot_id.svg) !important;
  }

  .icon-produce {
    background-image: url(../../svg/produce.svg) !important;
  }

  .icon-product {
    background-image: url(../../svg/product.svg) !important;
  }

  .icon-rename {
    background-image: url(../../svg/rename.svg) !important;
  }

  .icon-search_result {
    background-image: url(../../svg/search_result.svg) !important;
  }

  .icon-search {
    background-image: url(../../svg/search.svg) !important;
  }

  .icon-submission_complete {
    background-image: url(../../svg/submission_complete.svg) !important;
  }

  .icon-submission {
    background-image: url(../../svg/submission.svg) !important;
  }

  .icon-time {
    background-image: url(../../svg/time.svg) !important;
  }

  .icon-upload {
    background-image: url(../../svg/upload.svg) !important;
  }

  .icon-weather {
    background-image: url(../../svg/weather.svg) !important;
  }
}
