.uploader {
  display: flex;
}

.uploader :global(.du-wrap) {
  width: 100%;
}

.uploader :global(.du-dropzone) {
  padding: var(--theme_spacing);
}

.uploader :global(.du-wrap),
.uploader :global(.du-dropzone),
.uploader :global(.du-dropzone-inner) {
  display: block;
  box-sizing: border-box;
}

.uploader :global(.du-dropzone) {
  border: 1px dashed #cecece;
}

.uploader :global(.du-dropzone.dragging) {
  border: 1px dashed var(--theme_primary_color);
}

.uploader :global(.du-divider) {
  display: none;
}

.uploader :global(.du-dropzone-inner) {
  display: flex;
  justify-content: space-between;
}

.uploader :global(.du-files) {
  list-style-position: inside;
  list-style-type: decimal;
  margin-top: calc(var(--theme_spacing) / 2);
  margin-bottom: 0;
  padding: 0;
}

.uploader :global(.du-infobox),
.uploader :global(.du-files > li) {
  word-break: break-all;
}

.uploader :global(.btn) {
  white-space: nowrap;
  font-size: inherit;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: var(--theme_font_family);
  border-radius: 3px;
  color: var(--theme_primary_color);
  text-decoration: underline;
  padding: 0;
}

.uploader :global(.btn:hover),
.uploader :global(.btn:focus),
.uploader :global(.btn:active) {
  color: var(--theme_primary_color_hover);
}

.uploader :global(.driveuploader-replace) {
  display: none;
}

.left {
  width: 100%;
}

.right {
  margin-left: var(--theme_spacing);
}
