.itemRow + .itemRow {
  border-top: 2px solid var(--accept_photo_shoot_offer_delimiter_border_color);
}

.itemRow:last-child {
  border-bottom: 2px solid var(--accept_photo_shoot_offer_delimiter_border_color);
}

.acceptedOffer {
  background-color: #f6fff3;
}

.cell {
  vertical-align: top;
}

.fieldDateTime {
  display: block;
}

.fieldDuration {
  color: var(--theme_dimmed_text_color);
}

.fieldDuration::before {
  line-height: 32px;
  vertical-align: text-top;
}

.travelTimeAndDistance {
  display: block;
}

.fieldUnderAddress {
  display: block;
  color: var(--theme_dimmed_text_color);
}

@media (max-width: 720px) {
  .cell {
    width: 50%;
  }

  .cellPrice {
    text-align: center;
  }

  .cell:last-child {
    text-align: center;
  }

  .itemRow {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
  }

  .itemRow:nth-child(2n + 1):not(.acceptedOffer) {
    background-color: var(--accept_photo_shoot_offer_table_cell_background_color);
  }
}
