.root {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  display: inline-block;
  border: 0;
  font-family: var(--theme_font_family);
  line-height: 0;
  text-align: center;
  appearance: none;
  background: none;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  font-size: 14px;
}

.wrapper {
  display: table;
  margin: 0 auto;
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.disabled:not(.processing) {
  opacity: var(--theme_disabled_opacity);
  cursor: not-allowed;
}

.processing {
  cursor: wait;
}

.fullWidth {
  width: 100%;
}

/* SIZES */

.size-xs {
  min-width: 80px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.size-sm {
  min-width: 160px;
  height: 40px;
  padding-left: 18px;
  padding-right: 18px;
}

.size-md,
.size-lg,
.size-xl {
  min-width: 240px;
  height: 60px;
  padding-left: 38px;
  padding-right: 38px;
}

/* CONTAINED */

.variant-contained {
  border-radius: 3px;
  font-weight: bold;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.color-primary.variant-contained {
  color: var(--theme_primary_contrast_color);
  background-color: var(--theme_primary_color);
}

.color-primary.variant-contained:not(:disabled):hover,
.color-primary.variant-contained:not(:disabled):focus {
  background-color: var(--theme_primary_color_hover);
}

.color-secondary.variant-contained {
  color: var(--theme_secondary_contrast_color);
  background-color: var(--theme_secondary_color);
}

.color-secondary.variant-contained:not(:disabled):hover,
.color-secondary.variant-contained:not(:disabled):focus {
  background-color: var(--theme_secondary_color_hover);
}

/* TEXT */

.variant-text {
  font-weight: normal;
  text-decoration: underline;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.content + .content {
  padding-left: 8px;
}

.color-primary.variant-text {
  color: var(--theme_primary_color);
}

.color-primary.variant-text:not(:disabled):hover,
.color-primary.variant-text:not(:disabled):focus {
  color: var(--theme_primary_color_hover);
}

.color-secondary.variant-text {
  color: var(--theme_secondary_color);
}

.color-secondary.variant-text:not(:disabled):hover,
.color-secondary.variant-text:not(:disabled):focus {
  color: var(--theme_secondary_color_hover);
}

.variant-text {
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media (max-width: 720px) {
  .button {
    width: 100%;
    padding: 15px;
  }
}
