.block {
  height: 150px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: var(--theme_font_family);
}

.text {
  margin: auto;
  padding-top: 75px;
  text-align: center;
}
