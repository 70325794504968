.wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 16px;
}

.demo {
  display: block;
  height: 70vh;
  width: auto;
  max-width: 450px;
  max-height: 800px;
}

.demoLoaded {
  border-radius: 16px;
  border: 1px solid #ccc;
}

.overlay {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.logo {
  width: 36px;
  height: 36px;
  display: block;
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.text {
  font-family: inherit;
  margin-left: 16px;
}

.closeBtn {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: #dedfde;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 0;
  width: 42px;
  height: 42px;
  border: 0;
}
