.list {
  list-style: none;
  list-style-position: inside;
  padding: 0;
  margin: 0;
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  overflow-wrap: break-word;
  width: 100%;
}

.item {
  display: table-row;
}

.content {
  display: table-cell;
  vertical-align: top;
}

.item + .item > .content {
  padding-top: var(--theme_spacing);
}

.content:first-child {
  width: 21px;
}

:global([dir='ltr']) .content + .content {
  padding-left: var(--theme_spacing);
}

:global([dir='rtl']) .content + .content {
  padding-right: var(--theme_spacing);
}
