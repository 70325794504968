.root :global(.du-wrap) {
  border-radius: unset;
  margin: unset;
  font-family: unset;
  text-align: unset;
  display: unset;
  width: unset;
  height: unset;
  min-height: unset;
}

.root :global(.du-dropzone) {
  text-align: unset;
  display: unset;
  vertical-align: unset;
  width: unset;
  height: unset;
  border: unset;
  border-radius: unset;
  padding: unset;
  margin: unset;
  text-align: unset;
  font-size: unset;
  color: unset;
}

.root :global(.driveroot-fill > .du-wrap) {
  min-height: unset;
}

.root :global(.driveroot-fill > .du-wrap > .du-dropzone) {
  min-height: unset;
  width: unset;
  margin: unset;
}

.root :global(.du-dropzone.dragging) {
  border: unset;
  color: unset;
}

.root :global(.du-dropzone-inner) {
  color: unset;
  display: unset;
  vertical-align: unset;
  padding-bottom: unset;
}

.root :global(.du-divider) {
  font-size: unset;
  padding: unset;
  font-weight: unset;
}

.root :global(.du-choosebtn),
.root :global(.du-nextbtn) {
  margin: unset;
  position: unset;
  box-shadow: unset;
  background-color: unset;
  background-image: unset;
  background-image: unset;
  background: unset;
  border: unset;
  color: unset;
  border-radius: unset;
  cursor: unset;
  font-size: unset;
  font-weight: unset;
  text-align: unset;
  white-space: unset;
  height: unset;
  min-width: unset;
  outline: unset;
  padding: unset;
  cursor: unset;
  letter-spacing: unset;
  vertical-align: unset;
  text-overflow: unset;
  text-decoration: unset;
  line-height: unset;
}

.root :global(.du-choosebtn:hover),
.root :global(.du-choosebtn:active),
.root :global(.du-choosebtn:focus),
.root :global(.du-nextbtn:hover),
.root :global(.du-nextbtn:active),
.root :global(.du-nextbtn:focus) {
  box-shadow: unset;
  background-color: unset;
  background-image: unset;
  background-image: unset;
  border: unset;
}

.root :global(.du-file) {
  display: unset;
  height: unset;
}

.root :global(.du-infobox) {
  color: unset;
  font-size: unset;
  text-align: unset;
  padding-bottom: unset;
}

.root :global(.du-error) {
  font-size: unset;
  font-weight: unset;
}

.root :global(.du-progressbar) {
  height: unset;
  width: unset;
  background: unset;
  border: unset;
  color: unset;
  font-size: unset;
  text-align: unset;
  padding-right: unset;
  padding: unset;
  max-width: unset;
  line-height: unset;
  border-radius: unset;
}

.root :global(.du-progressbar-bg) {
  border-radius: unset;
  height: unset;
  width: unset;
  background-color: unset;
}

.root :global(.du-info) {
  display: unset;
  text-align: unset;
  vertical-align: unset;
  padding: unset;
}

.root :global(.du-veil) {
  text-align: unset;
  display: unset;
  background-color: unset;
  vertical-align: unset;
  width: unset;
  height: unset;
  border: unset;
  border-radius: unset;
  padding: unset;
  margin: unset;
  text-align: unset;
  font-size: unset;
  color: unset;
}

.root :global(.du-veil p) {
  margin-top: unset;
}

.root :global(.du-veil input) {
  display: unset;
  background: unset;
  outline: unset;
  border: unset;
  border-bottom: unset;
  padding: unset;
  margin-bottom: unset;
  border-radius: unset;
}

.root :global(.du-veil input:active),
.root :global(.du-veil input:focus) {
  outline: unset;
  border-bottom: unset;
  margin-bottom: unset;
}

@media screen and (max-height: 100px) {
  .root :global(.du-divider) {
    display: unset;
  }
}
