.wrapper {
  margin-left: calc(var(--theme_spacing) * -1);
  margin-right: calc(var(--theme_spacing) * -1);
  margin-top: calc(var(--theme_spacing) * -1);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table td {
  padding: 8px var(--theme_spacing);
}

.thead {
  text-align: left;
}

.thead::before,
.thead::after {
  content: '';
  width: 100%;
  height: 15px;
  display: block;
}

.cell {
  text-align: left;
  background: var(--accept_photo_shoot_offer_table_cell_background_color);
  padding: 8px;
  font-weight: normal;
}

.cellPrice {
  text-align: right;
}

@media (max-width: 425px) {
  .table {
    display: block;
  }
}
