.block + .block {
  margin-top: var(--theme_spacing);
}

.header {
  height: 56px;
}

.main {
  margin-bottom: auto;
}

.footer {
  padding-bottom: calc(var(--theme_spacing) / 2);
}
